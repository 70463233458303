import { Container } from '@material-ui/core';
import moment from 'moment';
import React, { useCallback, useContext, useEffect, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useHistory } from 'react-router-dom';
import Table, { createColumn } from '../../components/table';
import Title from '../../components/title';
import { AuthContext } from '../../context/authContext';
import { EventsFilterContext } from '../../context/eventsFIlterContext';
import { LocationContext } from '../../context/locationContext';
import { ADD_FILTER, ADD_ROWS_PER_PAGE } from '../../reducer/eventsFilterReducer';
import { getAccessPoint } from '../../service/accessPointsApi';
import { filterEvents, markAllEventsAsRead, markEventAsRead, searchEvents } from '../../service/eventsApi';
import { ACCESS_CHECK, ACCESS_DENIED, ACCESS_GRANTED, ACCESS_POINTS_MODULE, ALL, API_REQUEST_ERROR_MESSAGE, ASCENDING, DATE_FORMAT_YYYY_MM_DD, DATE_TIME_FORMAT, DEFAULT_DATE, DESCENDING, EVENTS, EVENTS_MODULE, FILTER_EVENT_FORMAT, GET, GRANTED, NAME } from '../../utility/constants';
import { createSelectItem, convertToUTC, parseIntegerParam } from '../../utility/helper';
import ModalEventsFilter from './events-filter';
import useStyles from './styles';

const columns = [
  createColumn("type", `type`, true, "string", false),
  createColumn("eventId", "ID", false, "numeric", true),
  createColumn("subType", `subType`, true, "string"),
  createColumn("dateCreated", `dateCreated`, true, "numeric", false, true),
  createColumn("location", 'location', true, "string"),
  createColumn("read", "actions", false, "string"),
];

const Events = (props) => {
  const { showToaster, handlePermissions, newEvent, setNewEvent } = props;

  const { t }          = useTranslation();
  const classes        = useStyles();
  const history        = useHistory();

  const { state : stateFilter , dispatch } = useContext(EventsFilterContext);
  const { state : locationState }          = useContext(LocationContext);
  const { state : administratorState }     = useContext(AuthContext); 
  const administratorId = administratorState.administrator.administratorId;

  const { selectedLocationIds }            = locationState;
  const { state, search, pathname }        = history.location;

  const params     = new URLSearchParams(search);

  const query      = params.get('keyword') ? params.get('keyword') : '';
  const size       = params.get('size') ? parseIntegerParam(params.get('size'), 10) : 10;
  const pageSize   = (stateFilter.rowsPerPage === 10 && size) ? size : stateFilter.rowsPerPage;
  const pageNumber = params.get('page') ? parseIntegerParam(params.get('page'), 1) : 1;
  const pageType   = params.get('events') ? params.get('events') : 'all';
  const totalSize  = pageSize > 100 ? 100 : pageSize;

  var initialPageTitle = '';
  if (pageType === GRANTED) {
    initialPageTitle = ACCESS_GRANTED;
  } else if (pageType === ALL.toLowerCase()) {
    initialPageTitle = EVENTS;
  } else {
    initialPageTitle = ALL.toLowerCase();
  }

  const isPageTypeGranted = pageType === GRANTED;
  const initialTypes = isPageTypeGranted ? [createSelectItem(1, ACCESS_CHECK)] : stateFilter.type;
  const initialSubTypes = isPageTypeGranted ? [createSelectItem(2, ACCESS_GRANTED)] : stateFilter.subTypes;

  const [isLoading, setIsLoading]                       = useState(false);
  const [rowsPerPage, setRowsPerPage]                   = useState(totalSize);
  const [page, setPage]                                 = useState(pageNumber === 0 ? 1 : pageNumber);
  const [events, setEvents]                             = useState([]);
  const [totalEvents, setTotalEvents]                   = useState(0);
  const [orderBy, setOrderBy]                           = useState(columns[3].id);
  const [order, setOrder]                               = useState(DESCENDING);
  const [keyword, setKeyword]                           = useState(query);
  const [open, setOpen]                                 = useState(false);
  const [accessPointList, setAccessPointList]           = useState([]);
  const [types, setTypes]                               = useState(initialTypes);
  const [subTypes, setSubTypes]                         = useState(initialSubTypes);
  const [validFrom, setValidFrom]                       = useState(stateFilter.from);
  const [validUntil, setValidUntil]                     = useState(stateFilter.until);
  const [accessPoints, setAccessPoints]                 = useState(stateFilter.accessPoints);
  const [credentialNumber, setCredentialNumber]         = useState(stateFilter.credentialNumber);
  const [isNotValid, setIsNotValid]                     = useState(false);
  const [allChecked, setAllChecked]                     = useState(false);
  const [isOpenFilter, setIsOpenFilter]                 = useState(false);
  const [controllers, setControllers]                   = useState(stateFilter.names);
  const [title, setTitle]                               = useState(initialPageTitle);
  const [listType, setListType]                         = useState(pageType);
  const [isAccessGranted, setIsAccessGranted]           = useState(isPageTypeGranted);
  const [isClearFilter, setIsClearFilter]               = useState(false);
  const [eventsFilterSubTypes, setEventsFilterSubTypes] = useState([]);
  const [ prevValues, setPrevValues ]                   = useState([]);

  const hasAccessPointPermission = handlePermissions(ACCESS_POINTS_MODULE, GET);

  const initFilterValues = useMemo(() => ({
    type            : types,
    validFrom       : validFrom,
    validUntil      : validUntil,
    subTypes        : subTypes,
    names           : controllers,
    accessPoints    : accessPoints,
    credentialNumber: credentialNumber
  }), [types, validFrom, validUntil, subTypes, accessPoints, credentialNumber, controllers]);
  
  const getSort = useCallback((values) => {
    return `${orderBy},${order}`;
  }, [order, orderBy]);

  const formatParams = (values) => {
    let formatted = '';

    if(values === undefined){
      return '';
    }
    if(values.length > 0){
      values.map((value) => {
        formatted += `,${value.name}`;
        return null;
      });
    }

    return (formatted.replace(/(^,)|(,$)/g, ""));
  }

  const getType = useCallback((values) => {
    return (formatParams(values.type) === ALL ? '' : formatParams(values.type));
  }, []);

  const getDateFrom = useCallback((values) => {
    if (isAccessGranted) {
      return state ? state.dateFrom : DEFAULT_DATE;
    } else {
      return (values.validFrom ? moment(values.validFrom).format(FILTER_EVENT_FORMAT) : DEFAULT_DATE);
    }
  }, [isAccessGranted, state]);

  const getDateUntil = useCallback((values) => {
    const dateUntil = moment().format(FILTER_EVENT_FORMAT);
    if (isAccessGranted) {
      return state ? state.dateUntil  : dateUntil;
    } else {
      return (values.validUntil ? moment(values.validUntil).format(FILTER_EVENT_FORMAT) : dateUntil);
    }
  }, [isAccessGranted, state]);

  const getSubTypes = useCallback((paramSubTypes, isAccessDenied) => {
    if (keyword) {
      return isAccessDenied ? ACCESS_DENIED : formatParams(paramSubTypes);
    } 

    return formatParams(paramSubTypes);
  }, [keyword]);

  const getParams = useCallback((values) => {
    const isAccessDenied = formatParams(subTypes).includes(ACCESS_DENIED);

    return {
      type            : keyword,
      subType         : isAccessDenied ? ACCESS_DENIED : getSubTypes(subTypes, isAccessDenied),
      types           : getType(values),
      subTypes        : getSubTypes(subTypes, isAccessDenied),
      from            : getDateFrom(initFilterValues),
      until           : getDateUntil(initFilterValues),
      accessPoint     : values.accessPoints ? values.accessPoints : null,
      credentialNumber: values.credentialNumber ? values.credentialNumber: null,
      names           : formatParams(values.names) ? formatParams(values.names) : null,
      keyword         : keyword,
      size            : rowsPerPage,
      page            : page - 1,
      sort            : getSort(values),
      locationIds     : selectedLocationIds.toString(),
      userId          : administratorId
    }
  }, [initFilterValues, keyword, page, rowsPerPage, subTypes, getSort, getDateFrom, getDateUntil, getType, getSubTypes, selectedLocationIds, administratorId]);

  const fetchData = useCallback(async (values) => {
    try {
      let response = '';
      if (keyword) {
        response = await searchEvents(getParams(values));
      } else {
        response = await filterEvents(getParams(values));
      }
      
      const { data } = response;
      const { number, size, totalElements, totalPages } = data.page;
      const totalNumber = number > totalPages ? totalPages : number;

      const newEvents = data._embedded.events.sort((eventA, eventB) => {
        if ((moment(eventA.dateCreated).format(DATE_TIME_FORMAT) === moment(eventB.dateCreated).format(DATE_TIME_FORMAT))) {
          if (((order === DESCENDING) && (eventA.eventId > eventB.eventId)) ||
              ((order === ASCENDING) && (eventA.type === eventB.type) && (eventA.eventId <= eventB.eventId)) ||
              ((order === ASCENDING) && (eventA.type !== eventB.type) && (eventA.eventId > eventB.eventId))) {
            return -1;
          } else {
            return 1;
          }
        }
        
        return null;
      }).map(event => {
        const { type, subType, dateCreated, isRead, location } = event;
        return {
          type        : type,
          id          : event.eventId,
          subType     : subType,
          dateCreated : convertToUTC(dateCreated).format(DATE_TIME_FORMAT),
          reader      : isRead,
          location    : location?.name
        }
      });
  
      if (newEvents.length || number === 0) {
        setPage(totalNumber + 1);
      } else {
        setPage(totalNumber);
      }
      
      setAllChecked(newEvents.every(item => item.reader));
      setRowsPerPage(size);
      setTotalEvents(totalElements);
      setEvents(newEvents);
    } catch (error) {
      showToaster(t('error'), t(API_REQUEST_ERROR_MESSAGE), 'error');
    } finally {
      setIsLoading(false);
    }
  }, [getParams, order, showToaster, t, keyword]);

  const getEvents = useCallback(() => {
    if (open) {
      return;
    }

    fetchData(stateFilter ?? initFilterValues);
  }, [stateFilter, open, initFilterValues, fetchData]);

  const getUrlParams = useCallback(() => {
    const keywordParam = keyword ? `&keyword=${keyword}` : '';
    const eventsParam  = `events=${listType}`;
    const pageParam    = `page=${page}`;
    const sizeParam    = `size=${rowsPerPage}`;
    
    if (stateFilter.rowsPerPage !== rowsPerPage) {
      dispatch({ type: ADD_ROWS_PER_PAGE, payload: rowsPerPage });
    }

    const location = {
      pathname: pathname,
      search  : `?${eventsParam}&${pageParam}&${sizeParam}${keywordParam}`,
      state   : state
    };

    history.replace(location);
  }, [keyword, page, rowsPerPage, listType, history, pathname, state, dispatch, stateFilter]);

  const handleWSMessage = useCallback(() => {
    if (!isOpenFilter) {
      fetchData(stateFilter)
    }
    setNewEvent(false);
  }, [fetchData, isOpenFilter, setNewEvent, stateFilter])

  useEffect(() => {
    let delayDebounce;
    
    if (!open) {
      setIsLoading(true);
    }

    delayDebounce = setTimeout(() => {
      getUrlParams();
      getEvents();
    }, 1000);

    return () => {
      delayDebounce && clearTimeout(delayDebounce);
    }
    
  }, [page, rowsPerPage, order, orderBy, listType, selectedLocationIds, open, getUrlParams, getEvents]);

  useEffect(() => {
    if (newEvent) {
      handleWSMessage()
    }
    
  }, [newEvent, handleWSMessage]);

  const getAccessPoints = useCallback( async () => {
    if (!hasAccessPointPermission) {
      return;
    }

    try {
      const response = await getAccessPoint(keyword, '', 1, NAME, ASCENDING);
      
      const newAccessPoints = response.accessPoints.map(accessPoint => {

        const { name, controller, location } = accessPoint;

        const accessPointID = accessPoint.id;

        const deviceId = controller.deviceId;

        return {
          id          : accessPointID,
          name        : name,
          displayName : `${deviceId}:${name}`,
          locationId  : location.location_id
        }
      });

      newAccessPoints.sort((apA, apB) => {
        if (order === DESCENDING) {
          if (apA.displayName > apB.displayName) {
            return -1;
          }

          return 1;
        } else {
          if (order === ASCENDING) {
            if (apA.displayName > apB.displayName) {
              return 1;
            }

            return -1;
          }
        }
        
        return null;
      });
      
      setAccessPointList(newAccessPoints);
    } catch {
      showToaster(t('error'), t(API_REQUEST_ERROR_MESSAGE), 'error');
    }
  }, [order, showToaster, t, hasAccessPointPermission, keyword]);

  useEffect(() => {
    getAccessPoints();
  }, [getAccessPoints]);

  useEffect(() => {
    if (stateFilter.listType) {
      setIsAccessGranted(false);
      setListType(ALL.toLowerCase());
      setTitle(ALL.toLowerCase());
    }
  }, [stateFilter.listType]);

  const handleSearch = (value) => {
    setPage(1);
    setKeyword(value);
  }

  const handleClearSearch = () => {
    setPage(1);
    setKeyword('');
  }

  const handleSort = (newOrderBy, newOrder) => {
    setPage(1);
    setOrderBy(newOrderBy);
    setOrder(newOrder);
  }

  const handleRowsPerPageChange = (newRowsPerPage) => {
    setPage(1);
    setRowsPerPage(newRowsPerPage);
  }

  const handleChangePage = (newPage) => {
    setPage(newPage + 1);
  }

  const handleView = (row) => {
    history.push(`/events/view/${row.id}`);
  }

  const clearFields = () => {
    setTypes([]);
    setSubTypes([]);
    setEventsFilterSubTypes([]);
    setAccessPoints('');
    setCredentialNumber('');
    setValidFrom(null);
    setControllers([]);
    setValidUntil(moment().format(DATE_FORMAT_YYYY_MM_DD));
  }

  const onFilter = () => {
    setOpen(true);
    setIsOpenFilter(true);
  };

  const onFilterClose = () => {
    setOpen(false);
    setIsOpenFilter(false);

    if (prevValues.length !== 0) {
      setTypes(prevValues.type);
      setSubTypes(prevValues.subTypes);
      setEventsFilterSubTypes(prevValues.subTypes);
      setAccessPoints(prevValues.accessPoints);
      setCredentialNumber(prevValues.credentialNumber);
      setValidFrom(prevValues.from);
      setControllers(prevValues.controllers);
      setValidUntil(prevValues.until);
      dispatch({ type: ADD_FILTER, payload: prevValues });
    } else {
      clearFields();
      setPrevValues([]);
    }

    setIsClearFilter(false);
  };

  const filterValues = (value, _formik) => {
    const payloadData = {
      ...value,
      from       : initFilterValues.validFrom,
      until      : initFilterValues.validUntil,
      names      : controllers,
      rowsPerPage: rowsPerPage,
      keyword    : keyword
    }

    delete payloadData.validFrom;
    delete payloadData.validUntil;

    const payload = prevValues.length && !isClearFilter ?  prevValues : payloadData;

    dispatch({ type: ADD_FILTER, payload: payload });
    
    setOpen(false);
    setIsOpenFilter(false);
    setPrevValues(isClearFilter ? [] : payload);

    if (page === 0 && keyword === "") {
      getEvents();
    } else {
      setPage(1);
    }
  };

  const handleMarkAsRead = async (row) => {
    try {
      const response = await markEventAsRead(row.id);
      setAllChecked(response.data.isRead);
      setKeyword('');
    } catch {
      setKeyword('');
      showToaster(t('error'), t(API_REQUEST_ERROR_MESSAGE), 'error');
    }
  }

  const handleMarkAllAsRead = async () => {
    if (allChecked) return;
    try {
      await markAllEventsAsRead();
      setKeyword('');
      getEvents();
    } catch {
      setKeyword('');
      showToaster(t('error'), t(API_REQUEST_ERROR_MESSAGE), 'error');
    }
  }

  const handleType = () => {
    setIsAccessGranted(false);
    setListType(ALL.toLowerCase());
    setTitle(ALL.toLowerCase());
  }

  return (
    <Container maxWidth="xl" className={classes.container}>
      <Title title={t(title)} listType={listType}/>
      <ModalEventsFilter
        accessPointList={accessPointList}
        controllers={controllers}
        filterValues={filterValues}
        handleClose={onFilterClose}
        initFilterValues={initFilterValues}
        isNotValid={isNotValid}
        open={open}
        setAccessPoints={setAccessPoints}
        setCredentialNumber={setCredentialNumber}
        setControllers={setControllers}
        setIsNotValid={setIsNotValid}
        setSubTypes={setSubTypes}
        setTypes={setTypes}
        setValidFrom={setValidFrom}
        setValidUntil={setValidUntil}
        subTypes={subTypes}
        types={types}
        dispatch={dispatch}
        isClearFilter={isClearFilter}
        setIsClearFilter={setIsClearFilter}
        eventsFilterSubTypes={eventsFilterSubTypes}
        setEventsFilterSubTypes={setEventsFilterSubTypes}
        clearFields={clearFields}
        setIsLoading={setIsLoading}
      />
      <Table
        columns={columns}
        data={events}
        isLoading={isLoading}
        keyword={keyword}
        label={EVENTS_MODULE}
        module={EVENTS_MODULE}
        onChangePage={handleChangePage}
        onClearSearch={handleClearSearch}
        onFilter={onFilter}
        onRowsPerPageChange={handleRowsPerPageChange}
        onSearch={handleSearch}
        onSort={handleSort}
        onView={handleView}
        orderBy={orderBy}
        order={order}
        page={page}
        rowsPerPage={stateFilter.rowsPerPage}
        totalItems={totalEvents}
        onMarkAsRead={handleMarkAsRead}
        onMarkAllAsRead={handleMarkAllAsRead}
        allChecked={allChecked}
        viewKey={"type"}
        listType={listType}
        onViewAll={handleType}
        handlePermissions={handlePermissions}
      />
    </Container>
  );
}

export default Events;
