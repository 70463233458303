import * as Yup from 'yup';
import { EMPTY_FIELD, INVALID_FORMAT, REQUIRED_FIELD, SETTING_REQUIRED_FIELD, SPECIAL_CHARACTER_VALIDATION } from '../utility/constants';

const ALLOWED_CHARACTERS = /^[^{[\]^<>|`}]*$/;

export const signInSchema = Yup.object({
  email           : Yup.string()
                    .email('emailIsInvalid')
                    .required(REQUIRED_FIELD),
  password        : Yup.string()
                       .min(8, 'passwordCharacters')
                       .required(REQUIRED_FIELD),
});

export const userSchema = Yup.object({
  personalNumber  : Yup.string()
                      .matches(ALLOWED_CHARACTERS, SPECIAL_CHARACTER_VALIDATION)
                      .min(6, 'personalNumberLength')
                      .required(REQUIRED_FIELD),
  firstName       : Yup.string()
                      .matches(ALLOWED_CHARACTERS, SPECIAL_CHARACTER_VALIDATION)
                      .required(REQUIRED_FIELD),
  lastName        : Yup.string()
                      .matches(ALLOWED_CHARACTERS, SPECIAL_CHARACTER_VALIDATION)
                      .required(REQUIRED_FIELD),
  validFrom       : Yup.date()
                       .required(REQUIRED_FIELD)
                       .typeError(INVALID_FORMAT)
                       .nullable(),
  validUntil      : Yup.date()
                       .required(REQUIRED_FIELD)
                       .typeError(INVALID_FORMAT)
                       .nullable(),
  location        : Yup.string()
                       .required(REQUIRED_FIELD),
  pin             : Yup.string()
                       .matches(/.{4,}/, {
                        excludeEmptyString: true
                      })
});

export const myAccountPasswordSchema = Yup.object({
  currentPassword: Yup.string()
                  .required(`my-account-page.${REQUIRED_FIELD}`)
                  .min(8, 'passwordCharacters'),
  newPassword: Yup.string()
                  .required(`my-account-page.${REQUIRED_FIELD}`)
                  .min(8, 'passwordCharacters')
                  .matches(/\d/, 'passwordRuleSchema1')
                  .matches(/[A-Z]/, 'passwordRuleSchema2')
                  .matches(/[a-z]/, 'passwordRuleSchema3')
                  .matches(/^(?=.*[!@#$%^&*])[a-zA-Z0-9!@#$%^&*]{6,16}$/, 'passwordRuleSchema4'),
  confirmPassword: Yup.string()
                      .required(`my-account-page.${REQUIRED_FIELD}`)
                      .min(8, 'passwordCharacters')
                      .matches(/\d/, 'passwordRuleSchema1')
                      .matches(/[A-Z]/, 'passwordRuleSchema2')
                      .matches(/[a-z]/, 'passwordRuleSchema3')
                      .matches(/^(?=.*[!@#$%^&*])[a-zA-Z0-9!@#$%^&*]{6,16}$/, 'passwordRuleSchema4')
                      .when('newPassword', {
                        is: (val) => (val && val.length > 0 ? true : false),
                        then: Yup.string().oneOf(
                          [Yup.ref('newPassword')],
                          "my-account-page.passwordNotMatched"
                        ),
                  }),
});



export const credentialSchema = Yup.object({
  credentialNumber: Yup.string()
                      .matches(ALLOWED_CHARACTERS, SPECIAL_CHARACTER_VALIDATION)
                      .required(REQUIRED_FIELD),
  validFrom       : Yup.date()
                       .required(REQUIRED_FIELD)
                       .typeError(INVALID_FORMAT)
                       .nullable(),
  validUntil      : Yup.date()
                       .required(REQUIRED_FIELD)
                       .typeError(INVALID_FORMAT)
                       .nullable(),
  location        : Yup.string()
                       .required(REQUIRED_FIELD)
});

export const userCredentialSchema = Yup.object({
  credentialNumber: Yup.string()
                      .matches(ALLOWED_CHARACTERS, SPECIAL_CHARACTER_VALIDATION)
                      .required(REQUIRED_FIELD),
  validFrom       : Yup.date()
                       .required(REQUIRED_FIELD)
                       .typeError(INVALID_FORMAT)
                       .nullable(),
  validUntil      : Yup.date()
                       .required(REQUIRED_FIELD)
                       .typeError(INVALID_FORMAT)
                       .nullable(),
  location        : Yup.string()
                       .required(REQUIRED_FIELD)
});

export const roleSchema = Yup.object({
  roleName        : Yup.string()
                      .matches(ALLOWED_CHARACTERS, SPECIAL_CHARACTER_VALIDATION)
                      .required(REQUIRED_FIELD),
  location        : Yup.string()
                       .required(REQUIRED_FIELD),
});

export const profileSchema = Yup.object({
  name            : Yup.string()
                      .matches(ALLOWED_CHARACTERS, SPECIAL_CHARACTER_VALIDATION)
                      .required(REQUIRED_FIELD),
  validFrom       : Yup.date()
                       .required(REQUIRED_FIELD)
                       .typeError(INVALID_FORMAT)
                       .nullable(),
  validUntil      : Yup.date()
                       .required(REQUIRED_FIELD)
                       .typeError(INVALID_FORMAT)
                       .nullable(),
  location        : Yup.string()
                       .required(REQUIRED_FIELD),
});

export const accessPointSchema = Yup.object({
  location        : Yup.string()
                       .required(REQUIRED_FIELD),
});

export const forgotPasswordSchema = Yup.object({
  email : Yup.string()
             .email('emailIsInvalid')
             .required(REQUIRED_FIELD),
});

export const newPasswordSchema = Yup.object({
  newPassword: Yup.string()
                  .required(REQUIRED_FIELD)
                  .min(8, 'passwordCharacters')
                  .matches(/\d/, 'passwordRuleSchema1')
                  .matches(/[A-Z]/, 'passwordRuleSchema2')
                  .matches(/^(?=.*[!@#$%^&*])[a-zA-Z0-9!@#$%^&*]{6,16}$/, 'passwordRuleSchema3'),
  confirmPassword: Yup.string()
                  .required(REQUIRED_FIELD)
                  .when('newPassword', {
                    is: (val) => (val && val.length > 0 ? true : false),
                    then: Yup.string().oneOf(
                      [Yup.ref('newPassword')],
                      "passwordNotMatched"
                    ),
                  }),
});

export const scheduleSchema = Yup.object({
  name            : Yup.string()
                      .matches(ALLOWED_CHARACTERS, SPECIAL_CHARACTER_VALIDATION)
                      .required(REQUIRED_FIELD),
  location        : Yup.string()
                       .required(REQUIRED_FIELD),
});
export const holidaySchema = Yup.object({
  name            : Yup.string()
                      .matches(ALLOWED_CHARACTERS, SPECIAL_CHARACTER_VALIDATION)
                      .required(REQUIRED_FIELD),
  location        : Yup.string()
                       .required(REQUIRED_FIELD),
});
export const holidayItemSchema = Yup.object({
  name            : Yup.string()
                      .matches(ALLOWED_CHARACTERS, SPECIAL_CHARACTER_VALIDATION)
                      .required(REQUIRED_FIELD),
  date            : Yup.date()
                       .required(REQUIRED_FIELD)
                       .typeError(INVALID_FORMAT),
});

export const groupSchema = Yup.object({
  groupName       : Yup.string()
                       .required(REQUIRED_FIELD),
});

export const administratorRoleSchema = Yup.object({
  administratorRole : Yup.string()
                       .required(REQUIRED_FIELD)
});

export const settingSchema = Yup.object({
  validFrom       : Yup.date()
                       .required(REQUIRED_FIELD)
                       .typeError(INVALID_FORMAT),
  validUntil      : Yup.date()
                       .required(REQUIRED_FIELD)
                       .typeError(INVALID_FORMAT),
  requestLimit    : Yup.number()
                       .typeError(SETTING_REQUIRED_FIELD),
  expiration      : Yup.number()
                       .typeError(SETTING_REQUIRED_FIELD)
})

export const createAdministratorSchema = Yup.object({
  firstName : Yup.string()
                 .required(REQUIRED_FIELD),
  lastName  : Yup.string()
                 .required(REQUIRED_FIELD),
  email     : Yup.string()
                .email('emailIsInvalid')
                .required(REQUIRED_FIELD)
                .matches(/^\w+([.-]?\w+)*@\w+([.-]?\w+)*(\.\w{2,3})+$/, 'emailIsInvalid'),
  role      : Yup.array()
                .of(Yup.object().shape({}))
                .min(1, REQUIRED_FIELD),
  locations : Yup.array()
                 .of(Yup.object().shape({}))
                 .min(1, REQUIRED_FIELD),
});

export const createSystemAdminSchema = Yup.object({
  firstName : Yup.string()
                 .required(REQUIRED_FIELD),
  lastName  : Yup.string()
                 .required(REQUIRED_FIELD),
  email     : Yup.string()
                .email('emailIsInvalid')
                .required(REQUIRED_FIELD)
                .matches(/^\w+([.-]?\w+)*@\w+([.-]?\w+)*(\.\w{2,3})+$/, 'emailIsInvalid'),
  role      : Yup.array()
                .of(Yup.object().shape({}))
                .min(1, REQUIRED_FIELD)
});

export const updateAdministratorSchema = Yup.object({
  firstName : Yup.string()
                 .required(REQUIRED_FIELD),
  lastName  : Yup.string()
                 .required(REQUIRED_FIELD),
  role      : Yup.array()
                .of(Yup.object().shape({}))
                .min(1, REQUIRED_FIELD),
  locations : Yup.array()
                .of(Yup.object().shape({}))
                .min(1, REQUIRED_FIELD),
});

export const updateSystemAdminSchema = Yup.object({
  firstName : Yup.string()
                 .required(REQUIRED_FIELD),
  lastName  : Yup.string()
                 .required(REQUIRED_FIELD),
  role      : Yup.array()
                .of(Yup.object().shape({}))
                .min(1, REQUIRED_FIELD)
});

export const wizardPersonSchema = Yup.object({
  personalNumber  : Yup.string()
                      .matches(ALLOWED_CHARACTERS, SPECIAL_CHARACTER_VALIDATION)
                      .min(6, 'personalNumberLength')
                      .required(REQUIRED_FIELD),
  firstName       : Yup.string()
                      .matches(ALLOWED_CHARACTERS, SPECIAL_CHARACTER_VALIDATION)
                      .required(REQUIRED_FIELD),
  lastName        : Yup.string()
                      .matches(ALLOWED_CHARACTERS, SPECIAL_CHARACTER_VALIDATION)
                      .required(REQUIRED_FIELD),
  validFrom       : Yup.date()
                       .required(REQUIRED_FIELD)
                       .typeError(INVALID_FORMAT)
                       .nullable(),
  validUntil      : Yup.date()
                       .required(REQUIRED_FIELD)
                       .typeError(INVALID_FORMAT)
                       .nullable(),
  location        : Yup.object({
    name    : Yup.string()
                 .required(REQUIRED_FIELD)
  })                
});

export const wizardCredentialSchema = Yup.object({
  credentialNumber: Yup.string()
                      .matches(ALLOWED_CHARACTERS, SPECIAL_CHARACTER_VALIDATION)
                      .required(REQUIRED_FIELD),
  location        : Yup.object({
    name    : Yup.string()
                 .required(REQUIRED_FIELD)
  })
});

export const controllerSchema = Yup.object({
  name      : Yup.string()
                 .required(`controller-page.${REQUIRED_FIELD}`)
                 .matches(/^\s*\S[\s\S]*$/, `controller-page.${EMPTY_FIELD}`),
  location  : Yup.string()
                 .required(`controller-page.${REQUIRED_FIELD}`)
});

export const locationSchema = Yup.object({
  name : Yup.string()
            .matches(ALLOWED_CHARACTERS, SPECIAL_CHARACTER_VALIDATION)
            .required(`locations-page.${REQUIRED_FIELD}`)
            .matches(/^\s*\S[\s\S]*$/, `locations-page.${EMPTY_FIELD}`)
            .max(32, 'locations-page.maximumCharacterError')
});