import moment from "moment";
import { FILTER_EVENT_FORMAT } from "../utility/constants";

export const ADD_FILTER        = 'ADD_FILTER';
export const CLEAR_FILTER      = 'CLEAR_FILTER';
export const ADD_ROWS_PER_PAGE = 'ADD_ROWS_PER_PAGE';
export const UPDATE_LIST_TYPE  = 'UPDATE_LIST_TYPE'; 

export const initialState = {
  type            : [],
  subTypes        : [],
  from            : null,
  until           : moment().format(FILTER_EVENT_FORMAT),
  accessPoints    : '',
  credentialNumber: '',
  names           : [],
  rowsPerPage     : 10,
  listType        : ''
}

export const EventsFilterReducer = (state, action) => {
  switch (action.type) {
    case ADD_FILTER:
      const {type, subTypes, from, until, accessPoints, credentialNumber, names } = action.payload;
      return {
        ...state,
        type            : type,
        subTypes        : subTypes,
        from            : from,
        until           : until,
        accessPoints    : accessPoints,
        credentialNumber: credentialNumber,
        names           : names
      }
    case ADD_ROWS_PER_PAGE:
      return {
        ...state,
        rowsPerPage : action.payload
      }
    case CLEAR_FILTER: {
      return initialState
    }
    case UPDATE_LIST_TYPE: {
      return {
        ...state,
        listType : action.payload
      }
    }
    default:
      return state
  }
}