import { default as axiosInstance } from 'axios';
import { BEARER, GET, KEYCLOAK_USER_SESSION_ID, X_LOCATION_ID } from '../utility/constants';
import { getLocalStorageItem, isTokenExpired } from '../utility/helper';
import { jwtDecode } from 'jwt-decode';

const axios = axiosInstance.create({
  baseURL: window.API_BASE_PATH
});

axios.interceptors.request.use(
  async (config) => {
    // TODO: Use keycloak token
    const token = getLocalStorageItem('token');
    const locations = getLocalStorageItem('locations');
    const selectedLocationIds = getLocalStorageItem('selectedLocationIds');
    const decodedToken = jwtDecode(token)
    
    if (token && isTokenExpired(token)) {
      localStorage.clear();
      window.location.href = '/expired';
    }

    if (token) {
      config.headers.Authorization = `${BEARER}${token}`;
    }

    if (decodedToken) {
      config.headers[KEYCLOAK_USER_SESSION_ID] = decodedToken.session_state;
    }

    // When selectedLocationIds is not undefined
    // When selectedLocationIds is not empty
    // When locations greater than 1
    // When HTTP method is GET
    // Add X-LOCATION-ID header to filter the record base don the location
    if (selectedLocationIds && selectedLocationIds.length > 0 && locations.length > 1 && config.method.toUpperCase().includes(GET) ) {
      config.headers[X_LOCATION_ID] = selectedLocationIds ? selectedLocationIds : '';
    }

    console.log(`Request from ${config.method.toUpperCase()} ${config.url}`, config);

    return config;
  }, (error) => {
    console.log(`Request error from ${error.config.method.toUpperCase()} ${error.config.url}`, error.response);

    return Promise.reject(error);
  }
);

axios.interceptors.response.use(
  (response) => {
    // Any status code that lie within the range of 2xx cause this function to trigger
    console.log(`Response from ${response.config.method.toUpperCase()} ${response.config.url}`, response);

    return response;
  }, (error) => {
    // Any status codes that falls outside the range of 2xx cause this function to trigger

    if (error.response) {
      // The request was made and the server responded with a status code
      // that falls out of the range of 2xx
      console.log(`Response error from ${error.config.method.toUpperCase()} ${error.config.url}`, error.response);
    } else if (error.request) {
      // The request was made but no response was received
      // `error.request` is an instance of XMLHttpRequest in the browser and an instance of
      // http.ClientRequest in node.js
      console.log(`Response error from ${error.config.method.toUpperCase()} ${error.config.url}`, error.request);
    } else {
      // Something happened in setting up the request that triggered an Error
      console.log(`Response error from ${error.config.method.toUpperCase()} ${error.config.url}`, error.message);
    }

    return Promise.reject(error);
  }
);

export default axios;