import { GET, PATCH } from "../utility/constants";
import api from "./api";
import { request } from "./requests";

export const searchEvents = async (params) => {
  return await request({
    url     : api.EVENTS_SEARCH,
    method  : GET,
    params  : params
  });
}

export const filterEvents = async (params) => {
  return await request({
    url     : api.EVENT_FIND_EVENTS_BY_FILTERED_DATA,
    method  : GET,
    params  : params
  });
}

export const getEventsByTypeAndSubType = async (type, subType, size, orderBy, order) => {
  return await request({
    url    : api.EVENTS_FILTER_NO_DATE,
    method : GET,
    params : {
      type    : type,
      subType : subType,
      size    : size,
      sort    : `${orderBy},${order}`
    }
  });
}

export const getEventsByTypeAndSubTypeAndDateCreated = async (params) => {
  return await request({
    url     : api.EVENTS_FILTER,
    method  : GET,
    params  : params
  });
}

export const getEventsMonthlyReport = async (params) => {
  return await request({
    url    : api.EVENT_FILTER_EVENT_MONTHLY_REPORT,
    method : GET,
    params : params
  })
}

export const getEventsYearlyReport = async (params) => {
  return await request({
    url    : api.EVENT_FILTER_EVENT_YEARLY_REPORT,
    method : GET,
    params : params
  })
}

export const getEventConfigurations = async () => {
  const response = await request({
    url    : api.EVENT_CONFIGURATIONS,
    method : GET
  });

  return response;
}

export const updateEventConfigurations = async (isEventExpirationEnabled, datepart, expiration) => {
  await request({
    url    : `${api.EVENT_CONFIGURATIONS}/1`,
    method : PATCH,
    data   : {
      isEventExpirationEnabled : isEventExpirationEnabled,
      datepart                 : datepart,
      expiration               : expiration
    }
  });
}

export const getEventsByPersonId = async (id, orderBy, order) => {
  const response = await request({
    url    : api.EVENT_FIND_EVENTS_BY_PERSON_ID,
    method : GET,
    params : {
      personId : id,
      sort     : `${orderBy},${order}`
    }
  });

  return response;
}

export const getAccessDeniedCount = async (locationIds) => {
  return await request({
    url    : api.ACCESS_DENIED_COUNT,
    method : GET,
    params : {
      locationIds : locationIds
    }
  });
}

export const markEventAsRead = async (id) => {
  return await request({
    url      : `${api.EVENTS}/${id}`,
    method   : PATCH,
    data     : {
      isRead : 1
    }
  });
}

export const markAllEventsAsRead = async () => {
  return await request({
    url     : api.EVENT_MARK_ALL_AS_READ,
    method  : PATCH
  });
}

export const getEventControllers = async (params) => {
  return await request({
    url : api.EVENT_GET_CONTROLLERS,
    method: GET,
    params: params
  })
}