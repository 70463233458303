import { makeStyles } from '@material-ui/core/styles';
import colors from '../../theme/colors';

export default makeStyles((theme) => ({
  container: {
    paddingTop   : theme.spacing(2),
    paddingBottom: theme.spacing(2),
  },
  paper: {
    marginTop          : theme.spacing(1),

    '& .MuiTextField-root': {
      marginTop        : theme.spacing(0)
    },
    '& p.MuiTypography-root.MuiTypography-body2':{
      color: "black"
    },
    '& input#userPersonalNumber.Mui-disabled':{
      color: "black"
    },
    '& textarea#userLastName.Mui-disabled':{
      color: "black"
    },
    '& textarea#userFirstName.Mui-disabled':{
      color: "black"
    },
    '& input#userValidFrom.Mui-disabled':{
      color: "black"
    },
    '& input#userValidUntil.Mui-disabled':{
      color: "black"
    },
    '& input#userViewPin.Mui-disabled':{
      color: "black"
    }
  },
  editIcon: {
    backgroundColor: theme.palette.primary.main,
    borderRadius   : theme.spacing(0.5),
    color          : theme.palette.common.white,
    display        : 'inline',  
  },
  form: {
    padding       : theme.spacing(1),
    paddingLeft   : theme.spacing(2),
    paddingRight  : theme.spacing(2),
  },
  calendarTextField: {
    '& .MuiInputBase-root' : {
      padding            : '0'
    },
  },
  containerCredentials: {  
    border          : 'thin',
    borderStyle     : 'solid',
    borderRadius    : '4px',
    borderColor     : '#c4c4c4',
    height          : '300px',
    marginTop       : theme.spacing(-1),
  },
  fieldsetLegend:{
    color           : '#767779'
  },
  hrDivider:  {
    border    : 0,
    borderTop : '1px dotted #767779',
    float     : 'right',
    width     : '100%',
  },
  action: {
    backgroundColor : theme.palette.grey[100],
    padding         : theme.spacing(2),
    textAlign       : 'right',

    '& .MuiButton-root:first-of-type': {
      marginRight: theme.spacing(2)
    }
  },
  view: {
    marginTop: theme.spacing(2)
  },
  skeleton: {
    display         : 'flex',
    justifyContent  : 'center',
    alignItems      : 'center',
    height          : '100%',
  },
  location: {
    paddingLeft: theme.spacing(1)
  },
  pin: {
    marginBottom: '4px'
  },
  pinDescription: {
    color       : colors.SECONDARY_TEXT,
    fontSize    : '12px',
    lineHeight  : '16px',
    marginLeft  : '16px',
    marginRight : '16px'
  },
  pinDescriptionError: {
    color       : colors.ERROR,
    fontSize    : '12px',
    lineHeight  : '16px',
    marginLeft  : '16px',
    marginRight : '16px'
  }
}));