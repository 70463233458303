import { useKeycloak } from '@react-keycloak/web';
import i18n from "i18next";
import cookies from "js-cookie";
import moment from "moment";
import { createContext, useCallback, useEffect, useReducer, useState } from "react";
import { useHistory } from "react-router-dom/cjs/react-router-dom.min";
import Loading from '../components/loading';
import AuthReducer, { LOGOUT, UPDATE_ACCOUNT, UPDATE_DEFAULT_VALIDITY, initialState } from '../reducer/authReducer';
import api from "../service/api";
import { request } from "../service/requests";
import { BEARER, DATE_FORMAT_YYYY_MM_DD, GET, KEYCLOAK_HEADERS, LANGUAGE_EN, LOCAL, POST, SYSTEM_ADMIN } from "../utility/constants";
import { getLocalStorageItem, setLocalStorageItem } from "../utility/helper";

export const AuthContext = createContext();

const capitalizeFirstLetter = (name) => {
  const splitNames = name.toLowerCase().split('_');

  const newName = splitNames.map(splitName => {
    return splitName.charAt(0).toUpperCase() + splitName.slice(1);
  });

  return newName.join(' ');
}

const convertToPermissionsData = (data) => {
  return data.map(item => {
    const { rsname, scopes }  = item;
    const name                = rsname.replace('res:', '');
    const capitalize          = capitalizeFirstLetter(name);

    return {
      name  : `${capitalize}s`,
      scopes: scopes
    }
  });
}

const AuthContextProvider = ({ children }) => {
  const [state, dispatch] = useReducer(AuthReducer, initialState);

  const history                                       = useHistory();
  const { initialized, keycloak }                     = useKeycloak()
  const { token }                                     = keycloak;
  const [administratorLoaded, setAdministratorLoaded] = useState(false);
  
  const getDefaultValidityDates = useCallback(async () => {
    const response = await request({
      url   : api.VALIDITY_DATE_CONFIGURATIONS,
      method: GET,
      headers : {
        Authorization: BEARER + token
      }
    });

    const validityConfiguration = response?.data[0];

    const defaultValidFrom  = response?.data.length ? moment(validityConfiguration.validFrom).format(DATE_FORMAT_YYYY_MM_DD) : moment().format(DATE_FORMAT_YYYY_MM_DD);
    const defaultValidUntil = response?.data.length ? moment(validityConfiguration.validUntil).format(DATE_FORMAT_YYYY_MM_DD) : moment('2099-12-31T15:00').format(DATE_FORMAT_YYYY_MM_DD);

    dispatch({
      type: UPDATE_DEFAULT_VALIDITY,
      payload: {
        defaultValidUntil: defaultValidUntil,
        defaultValidFrom : defaultValidFrom
      }
    });
  }, [token]);

  const getAdministratorPermissions = useCallback(async (administratorData) => {

    const audienceList = [KEYCLOAK_HEADERS.PAC_SERVER_AUDIENCE, KEYCLOAK_HEADERS.DEVICE_MANAGEMENT_SERVER_AUDIENCE];

    const permissions = await Promise.all(
      audienceList.map(async (audience) => {
        const params = {
          grant_type   : KEYCLOAK_HEADERS.GRANT_TYPE,
          audience     : audience,
          response_mode: KEYCLOAK_HEADERS.RESPONSE_MODE,
        }
      
        const response = await request({
          url   : api.KEYCLOAK_PERMISSIONS,
          method: POST,
          data  : new URLSearchParams(params),
          headers : {
            'Content-Type' : 'application/x-www-form-urlencoded',
            'Authorization': BEARER + token
          },
        });

        const { data } = response;
        return data;
      })
    );

    const formattedPermissions = convertToPermissionsData(permissions.flat()); 
    administratorData.administrator.permissions  = formattedPermissions;

    return administratorData;
  }, [token]);

  const getAdministratorLocationData = (administratorId, locationData) => {
    return locationData.map(administratorLocation => {
      return {
        administratorId : administratorId,
        locationId      : administratorLocation.locationId,
        name            : administratorLocation.name
      }
    }).flat();
  }

  const getAdministratorLocations = useCallback(async (administratorData) => {
    if (!administratorData.administrator.roles.length) {
      return administratorData;
    }

    const response = await request({
      url     : `${api.LOCATIONS_FIND_BY_ADMINISTRATOR_ID}/${administratorData.administrator.administratorId}`,
      method  : GET,
      headers : {
        'Authorization': BEARER + token
      },
    })

    const { data } = response;
    const locations = getAdministratorLocationData(administratorData.administrator.administratorId, data);
    administratorData.administrator.locations = locations;

    return administratorData;
  }, [token]);

  const loadMyAdministrator = useCallback(() => {
    keycloak.loadUserProfile()
    .then((profile) => {
      const locale = (profile.attributes.locale) ? profile.attributes.locale[0] : LANGUAGE_EN;
      i18n.changeLanguage(locale);
      cookies.set('KEYCLOAK_LOCALE', locale);

      const prevRoles = getLocalStorageItem('roles')

      const roles = keycloak.resourceAccess["pacaas-web-client"]?.roles ? keycloak.resourceAccess["pacaas-web-client"].roles : [];
      const realmPermissions = keycloak.resourceAccess["realm-management"]?.roles ? keycloak.resourceAccess["realm-management"].roles : [];
      const isRoleChange = prevRoles && JSON.stringify(prevRoles) !== JSON.stringify(roles);

      const isSystemAdmin = roles.includes(role => role === SYSTEM_ADMIN);

      if (isRoleChange && !isSystemAdmin) {
        history.push('/dashboard');
      }

      setLocalStorageItem('roles', roles);

      return {
        administrator: {
          administratorId    : profile.id,
          email              : profile.email,
          firstName          : profile.firstName,
          lastName           : profile.lastName,
          roles              : roles,
          locations          : [],
          permissions        : [],
          keycloakAttributes : profile.attributes,
          realmPermissions   : realmPermissions
        },
      }
    })
    .then((administratorData) => getAdministratorPermissions(administratorData))
    .then((administratorData) => getAdministratorLocations(administratorData))
    .then((administratorData)  => { 
      dispatch({
        type: UPDATE_ACCOUNT,
        payload: administratorData
      });
      
    })
    .finally(() => {
      setAdministratorLoaded(true);  
    })
    .catch((err) => {
       console.log("Error during Administrator Load", err)
    });

    getDefaultValidityDates();
  }, [keycloak, history, getAdministratorPermissions, getDefaultValidityDates, getAdministratorLocations]);

  useEffect(() => {
    if (initialized && !keycloak.authenticated) {
      keycloak?.login()
    } 

    if (initialized && keycloak.authenticated && !administratorLoaded){
      loadMyAdministrator();
    }
  }, [initialized, keycloak, keycloak.authenticated, administratorLoaded,
     loadMyAdministrator, getAdministratorLocations]);

  useEffect(() => {
    setLocalStorageItem('defaultValidFrom', state.defaultValidFrom);
    setLocalStorageItem('defaultValidUntil', state.defaultValidUntil);
    setLocalStorageItem('permissions', state.administrator?.permissions);
    setLocalStorageItem('locations', state.administrator?.locations);
    // setLocalStorageItem('keycloakAttributes', state.administrator?.keycloakAttributes);
  }, [state])

  if (!initialized || !keycloak.authenticated || !administratorLoaded) {
    return <Loading isOpen/>
  }

  const logout = () => {
    const basePath      = window.ENVIRONMENT === LOCAL ? window.AUTH_BASE_PATH : window.API_BASE_PATH;
    const dashboardPath = basePath.replace('api', 'dashboard');
    keycloak.logout({ redirectUri: dashboardPath});
    dispatch({
      type: LOGOUT,
    });
  }

  return (
    <AuthContext.Provider value={{state, dispatch, logout}}>
        {
            children
        }
    </AuthContext.Provider>

  )
}

export default AuthContextProvider;