import React from 'react';
import moment from 'moment';
import PropTypes from 'prop-types';
import { Table, TableBody, TableCell, TableContainer, TableRow, IconButton, Box, TableFooter, TablePagination } from '@material-ui/core';
import useStyles from './styles';
import Empty from '../../../components/empty';
import { KeyboardArrowLeft, KeyboardArrowRight } from '@material-ui/icons';
import { TableEventsSkeleton } from '../dashboard-skeleton';
import { DATE_TIME_FORMAT } from '../../../utility/constants';
import { useTranslation } from 'react-i18next';

const TablePaginationActions = (props) => {
  const { count, page, rowsPerPage, onPageChange } = props;

  const handleBackButtonClick = (event) => {
    onPageChange(event, page - 1);
  };

  const handleNextButtonClick = (event) => {
    onPageChange(event, page + 1);
  };

  return (
    <Box sx={{ flexShrink: 0, ml: 2.5 }}>
      <IconButton
        id="dashboardEventsPreviousPageButton"
        onClick={handleBackButtonClick}
        disabled={page === 0}
        aria-label="previous page"
      >
        {<KeyboardArrowLeft /> }
      </IconButton>
      <IconButton
        id="dashboardEventsNextPageButton"
        onClick={handleNextButtonClick}
        disabled={page >= Math.ceil(count / rowsPerPage) - 1}
        aria-label="next page"
      >
        <KeyboardArrowRight />
      </IconButton>
    </Box>
  );
}

TablePaginationActions.propTypes = {
  count: PropTypes.number.isRequired,
  onPageChange: PropTypes.func.isRequired,
  page: PropTypes.number.isRequired,
  rowsPerPage: PropTypes.number.isRequired,
};

const EventContents = (props) => {
  const classes = useStyles();
  const { events } = props;

  const hasEvents = events?.length;
  
  return (
    <>
    {
       hasEvents ?
        <TableBody className={classes.table}>
          {
            events.map((event, index) => {
              const id   = event.id;
              const date = moment(event.date).format(DATE_TIME_FORMAT);
              return (
                <TableRow id={`dashboardEvents${id}`} className={classes.contents} key={index}>
                  <TableCell id={`dashboardEvents${id}TypeCell`} component="th" scope="row">
                    <span id={`dashboardEvents${id}Type`}>{event.type}</span>
                  </TableCell>
                  <TableCell id={`dashboardEvents${id}DateCreatedCell`} scope="row">
                    <span id={`dashboardEvents${id}DateCreated`}>{date}</span>
                  </TableCell>
                </TableRow>
              )
            })
          }
        </TableBody>
      :
        <Box className={classes.empty}>
          <Empty variant={'h6'} />
        </Box>
    }
    </>
  )
}

const EventsContainer = (props) => {
  const { events, page, rowsPerPage, handleChangePage, handleChangeRowsPerPage, totalElement, tableContentIsLoading } = props;
  const classes = useStyles();
  const { t }   = useTranslation();

  const countElement = (count, to) => {
    return count !== -1 ? count : `more than ${to}`
  }
  
  return (
    <TableContainer className={classes.eventsContainer}>
      <Table sx={{ minWidth: 500 }} className={classes.table}>
        { tableContentIsLoading ?
            <TableEventsSkeleton />
          :
            <EventContents events={events} />
        }
        <TableFooter sx={{ minWidth: 500 }}>
          <TableRow>
            <TablePagination
              className={classes.pagination}
              rowsPerPageOptions={[5, 10,]}
              colSpan={3}
              count={totalElement}
              rowsPerPage={rowsPerPage}
              page={page}
              labelDisplayedRows={function defaultLabelDisplayedRows({ from, to, count }) { return `${from}–${to} ${t('of')} ${countElement(count, to)}`; }}
              labelRowsPerPage={t('rowPerPage')}
              SelectProps={{
                native: false,
              }}
              onPageChange={handleChangePage}
              onRowsPerPageChange={handleChangeRowsPerPage}
              ActionsComponent={TablePaginationActions}
            />
          </TableRow>
        </TableFooter>
      </Table>
    </TableContainer>
  )
}

export default EventsContainer;