const images = {
  CHAMELEON                  : `${window.STORAGE_ACCOUNT}/loading.gif`,
  COMING_SOON                : `${window.STORAGE_ACCOUNT}/coming-soon.svg`,
  CONTROLLER_CREDENTIALS_ICON: `${window.STORAGE_ACCOUNT}/controller-credentials-icon.svg`,
  REBOOT_CONTROLLER_SVG      : `${window.STORAGE_ACCOUNT}/controller-reboot.svg`,
  REBUILD_CONTROLLER_SVG     : `${window.STORAGE_ACCOUNT}/controller-rebuild.svg`,
  UPDATE_CONTROLLER_SVG      : `${window.STORAGE_ACCOUNT}/controller-update.svg`,
  X2CPASS_CONTROLLER         : `${window.STORAGE_ACCOUNT}/x2cpass-controller.png`,
  DELETE_MODAL               : `${window.STORAGE_ACCOUNT}/delete-modal.svg`,
  DOWNLOAD_REPORT            : `${window.STORAGE_ACCOUNT}/download-report.svg`,
  EMPTY_TABLE                : `${window.STORAGE_ACCOUNT}/empty-table.svg`,
  ERROR404                   : `${window.STORAGE_ACCOUNT}/error.svg`,
  IMPORT_CSV_SVG             : `${window.STORAGE_ACCOUNT}/import-csv.svg`,
  SESAMSEC_ICON              : `${window.STORAGE_ACCOUNT}/sesamlab-icon.ico`,
  SESAMLAB_LOGO              : `${window.STORAGE_ACCOUNT}/sesamlab-logo.png`,
  SESAMSEC_LOGO              : `${window.STORAGE_ACCOUNT}/sesamsec-logo.png`,
  SIGNIN_BG                  : `${window.STORAGE_ACCOUNT}/signin-bg.png`,
  UNSAVED_CHANGES_SVG        : `${window.STORAGE_ACCOUNT}/unsaved-changes.svg`,
  SETUP_WIZARD               : `${window.STORAGE_ACCOUNT}/wizard-modal.svg`,
  SAMPLE_CSV_FILE            : `${window.STORAGE_ACCOUNT}/CSVFileSample.csv`,
  BLOCK                      : `${window.STORAGE_ACCOUNT}/block.svg`,
  ONBOARD_NEW_PERSON_SVG     : `${window.STORAGE_ACCOUNT}/onboard-new-person.svg`,
  REPLACE_LOST_CREDENTIAL_SVG: `${window.STORAGE_ACCOUNT}/replace-lost-credential.svg`,
  CLOCK                      : `${window.STORAGE_ACCOUNT}/clock.svg`,
  NO_PERMISSION_SVG          : `${window.STORAGE_ACCOUNT}/no-permission.svg`,
  NO_ROLES_SVG               : `${window.STORAGE_ACCOUNT}/no-roles.svg`,
  ERROR403                   : `${window.STORAGE_ACCOUNT}/403.svg`,
  FORBIDDEN                  : `${window.STORAGE_ACCOUNT}/forbidden.svg`,
  COPY_ICON                  : `${window.STORAGE_ACCOUNT}/copy-icon.svg`,
  COPY_ICON_HOVER            : `${window.STORAGE_ACCOUNT}/copy-icon-hover.svg`,
  VISIBILITY_ON              : `${window.STORAGE_ACCOUNT}/visibility-on.svg`,
  VISIBILITY_OFF             : `${window.STORAGE_ACCOUNT}/visibility-off.svg`,
  SECPASS_CONTROLLER         : `${window.STORAGE_ACCOUNT}/secpass-controller.png`,
  NO_ROLES_ASSIGNED          : `${window.STORAGE_ACCOUNT}/no-roles-assigned.svg`,
  LOCATION_ICON              : `${window.STORAGE_ACCOUNT}/location-icon.svg`,
  AREA_ICON                  : `${window.STORAGE_ACCOUNT}/area-icon.svg`,
  EDIT_ICON                  : `${window.STORAGE_ACCOUNT}/edit-icon.svg`,
  ADD_ICON                   : `${window.STORAGE_ACCOUNT}/add-icon.svg`,
  DELETE_ICON                : `${window.STORAGE_ACCOUNT}/delete-icon.svg`,
  CHECK_ICON                 : `${window.STORAGE_ACCOUNT}/check-icon.svg`,
  DELETE_MODAL_ICON          : `${window.STORAGE_ACCOUNT}/delete-modal-icon.svg`,
  X2CPASS_PLUS_CONTROLLER    : `${window.STORAGE_ACCOUNT}/x2cpass+-controller.png`,
  DIALPAD_OFF_ICON           : `${window.STORAGE_ACCOUNT}/dialpad-off.svg`,
  BADGE_OFF_ICON             : `${window.STORAGE_ACCOUNT}/badge-off.svg`,
}

export default images;