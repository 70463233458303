//CDN CLOUD STORAGE
export const CDN = 'https://pacaascloudstorage.blob.core.windows.net/public-images'

// AXIOS CONFIGURATION
export const BEARER           = 'Bearer ';
export const TOKEN            = 'Token ';
export const CSRF_TOKEN       = 'nocheck';
export const TIMEOUT_LIMIT    = 100;
export const XML_HTTP_REQUEST = 'XMLHttpRequest';

// ENVIRONMENT
export const LOCAL = 'LOCAL';
export const TEST = 'TEST';
export const PRODUCTION = 'PRODUCTION';

// HTTP METHOD
export const GET    = 'GET';
export const POST   = 'POST';
export const PATCH  = 'PATCH';
export const PUT    = 'PUT';
export const DELETE = 'DELETE';

//HTTP REQUEST HEADER
export const X_LOCATION_ID = 'X-LOCATION-ID';
export const KEYCLOAK_USER_SESSION_ID = 'Keycloak-User-Session-Id';

// PROFILE SYNC PARAMS
export const ACCESS_POINT_ID = 'accessPointId='
export const SCHEDULE_ID     = 'scheduleId='

// SORT ORDER
export const ASCENDING  = 'asc';
export const DESCENDING = 'desc';

//ADMINISTRATORS
export const STATUS_BLOCKED    = 'BLO';
export const STATUS_ACTIVE     = 'ACT';
export const STATUS_NEW        = 'NEW';
export const STATUS_INACTIVE   = 'INA';
export const STATUS_DISABLE    = 'DIS';

export const ADMINISTRATOR_BLOCKED    = 'Blocked';
export const ADMINISTRATOR_ACTIVE     = 'Active';
export const ADMINISTRATOR_NEW        = 'New';
export const ADMINISTRATOR_INACTIVE   = 'Inactive';
export const ADMINISTRATOR_DISABLE    = 'Disabled';


//SCHEDULE TERMS
export const DAILY     = 'Daily';
export const WEEKDAYS  = 'Weekdays';
export const WEEKENDS  = 'Weekends';
export const TIME_FROM  = 'timeFrom';
export const TIME_UNTIL = 'timeUntil';
export const NAME      = 'name';

// SKELETON HEIGHT
export const TEXT_FIELD_SKELETON  = 55.972;
export const DESCRIPTION_SKELETON = 150.312;

//SCHEMA
export const REQUIRED_FIELD         = 'fieldIsRequired';
export const SETTING_REQUIRED_FIELD = 'settings-page.settingFieldIsRequired';
export const EMPTY_FIELD            = 'emptyField';
export const INVALID_DATE           = 'invalidDateInput';
export const INVALID_FORMAT         = 'invalidDateFormat';
export const SPECIAL_CHARACTER_VALIDATION = 'specialCharacterValidation';

//API REQUEST FAILED MESSAGE
export const API_REQUEST_ERROR_MESSAGE = 'apiRequestFailed';
export const API_REQUEST_ERROR_MESSAGE_DATA_USED = 'isCurrentlyAssociatedWithOtherRecords';

//DATE FORMAT
export const DATE_FORMAT = 'DD.MM.YYYY';
export const DATE_FORMAT_YYYY_MM_DD = 'YYYY-MM-DD';
export const EVENT_DATE_FORMAT = 'DD.MM.YYYY HH:MM';
export const DATE_TIME_FORMAT = 'DD.MM.YYYY HH:mm:ss';
export const FILTER_EVENT_FORMAT = 'YYYY/MM/DD';
export const EVENT_FILTER_FORMAT = 'DD/MM/YYYY';
export const EVENT_FILTER_FORMAT2 = 'MM/DD/YYYY';

//DATE
export const DEFAULT_DATE = '2021/01/01';

//ACCESS POINT DEVICE STATUS
export const DEVICE_STATUS_ONLINE = 'ONL';

//MENU ITEMS
export const DASHBOARD                 = 'dashboard';
export const ADMINISTRATORS            = 'administrators';
export const ROLE_BASED_ACCESS_CONTROL = 'roleBasedAccessControl';
export const PERSONS                   = 'persons';
export const CREDENTIALS               = 'credentials';
export const ROLES                     = 'roles';
export const PROFILES                  = 'profiles';
export const ACCESS_POINTS_MANAGEMENT  = 'accessPointsManagement';
export const ACCESS_POINTS             = 'accessPoints';
export const ACCESS_POINT              = 'access-points';
export const GROUPS                    = 'groups';
export const SCHEDULES                 = 'schedules';
export const LOCATIONS                 = 'locations';
export const STANDARD_SCHEDULES        = 'schedules';
export const STANDARD_SCHEDULE         = 'schedule';
export const HOLIDAYS                  = 'holidays';
export const EVENTS                    = 'events';
export const CONTROLLERS               = 'controllers';
export const SETTINGS                  = 'settings';
export const USERS                     = 'users';
export const USER                      = 'user'; 

export const LOCATION_FILTER           = 'Location Filter'; 

export const ADMINISTRATOR_ROLES       = 'administratorRoles';
export const ADMINISTRATOR_ROLE        = 'administrator-roles';
export const SYSTEM_ROLES              = 'system-roles';

export const TENANTS                   = 'tenants';
export const SYSTEM                    = 'SYSTEM';
export const PAC                       = 'PAC';
export const HOLIDAY_CALENDAR          = 'Holiday Calendar';

//MODULES
export const DASHBOARD_MODULE                 = 'Dashboard';
export const ROLE_BASED_ACCESS_CONTROL_MODULE = 'Role Based Access Control';
export const PERSON_MODULE                    = 'Person';
export const PERSONS_MODULE                   = 'Persons';
export const CREDENTIALS_MODULE               = 'Credentials';
export const CREDENTIAL                       = 'Credential';
export const ROLES_MODULE                     = 'Roles';
export const PROFILES_MODULE                  = 'Profiles';
export const PROFILE                          = 'Profile';
export const ACCESS_POINT_MODULE              = 'Access Point';
export const ACCESS_POINTS_MANAGEMENT_MODULE  = 'Access Points Management';
export const ACCESS_POINTS_MODULE             = 'Access Points';
export const ACCESSPOINTS_MODULE              = 'AccessPoints';
export const GROUPS_MODULE                    = 'Groups';
export const SCHEDULES_MODULE                 = 'Schedules';
export const SCHEDULE_MODULE                  = 'Schedule';
export const STANDARD_SCHEDULE_MODULE         = 'Schedules';
export const HOLIDAY                          = 'Holiday';
export const HOLIDAYS_MODULE                  = 'Holidays';
export const HOLIDAY_ITEMS_MODULE             = 'HolidayItems';
export const EVENTS_MODULE                    = 'Events';
export const CONTROLLER                       = 'Controller';
export const CONTROLLERS_MODULE               = 'Controllers';
export const SETUP_MODULE                     = 'Setup';
export const SETTINGS_MODULE                  = 'Settings';
export const SYSTEM_ROLE                      = 'System Role';
export const SYSTEM_ROLES_MODULE              = 'System Roles';
export const LOCATIONS_MODULE                 = 'Locations';
export const LOCATION_MODULE                  = 'Location';
export const SCHEDULE                         = 'Schedule';
export const ROLE                             = 'Role';
export const USER_MODULE                      = 'User';
export const USERS_MODULE                     = 'Users';
export const ADMINISTRATOR_ROLES_MODULE       = 'User Roles';
export const ADMINISTRATORS_MODULE            = 'Administrators';

//EVENT TYPES
export const ALL                    = 'All'
export const DOOR                   = 'Door';
export const READER                 = 'Reader';
export const ACCESS_CHECK           = 'Access Check';
export const EMA                    = 'EMA';
export const SERVICE                = 'Service';
export const REMOTE_CONTROL         = 'Remote Control';
export const IMPORT_EXPORT          = 'Import Export';
export const SDDL                   = 'SDDL';
export const UPDATE_DAEMON_TYPE     = 'Update Daemon';
export const DEVICE_REQUEST         = 'Device Request';

//EVENTS SUBTYPES
//ACCESS CHECK
export const ACCESS_GRANTED                   = 'Access Granted';
export const ACCESS_GRANTED_PINCODE           = 'Access Granted (Pincode)';
export const ACCESS_DENIED_NO_DOOR            = 'Access Denied, No Door';
export const ACCESS_DENIED_PINCODE_DOOR       = 'Access Denied (Pincode), No Door';
export const ACCESS_DENIED_INVALID            = 'Access Denied, Invalid Person';
export const ACCESS_DENIED_PINCODE_INVALID    = 'Access Denied (Pincode), Invalid Person';
export const ACCESS_DENIED_INVALID_BADGE      = 'Access Denied, Invalid Badge';
export const ACCESS_DENIED_INACTIVE_BADGE     = 'Access Denied, Inactive Badge';
export const ACCESS_DENIED_DATE_TIME          = 'Access Denied, Access Group Schedule does not match with Datetime';
export const ACCESS_DENIED_PINCODE_DATE_TIME  = 'Access Denied (Pincode), Access Group Schedule does not match with Datetime';
export const ACCESS_DENIED_NO_ZONE            = 'Access Denied, No Zone';
export const ACCESS_DENIED_CONTROL_POINTS     = 'Access Denied, Controlpoint does not match to List';
export const ACCESS_DENIED_READER             = 'Access Denied, Reader not in Controlpoint';
export const ACCESS_DENIED_AREA               = 'Access Denied, No Controlpoint for Area';
export const ACCESS_DENIED_ROLE               = 'Access Denied, no Access Group for Role';
export const ACCESS_DENIED_PERSON             = 'Access Denied, no Role for Person';
export const ACCESS_DENIED_PINCODE_PERSON     = 'Access Denied (Pincode), no Role for Person';
export const ACCESS_DENIED_INVALID_PINCODE    = 'Access Denied, Invalid Pincode';
export const ACCESS_DENIED_COMPANY_CODE       = 'Access Denied, Invalid Companycode';
export const ACCESS_DENIED_HOLIDAY            = 'Access Denied, Holiday';

//DOOR
export const FORCE_OPEN             = 'Forced Open';
export const FORCE_OPEN_ARMED       = 'Forced Open while armed';
export const DOOR_OPEN              = 'Door Open';
export const DOOR_CLOSED            = 'Door Closed';
export const DOOR_OPEN_PRE_ALARM    = 'Door Open Too Long Prealarm';
export const DOOR_OPEN_LONG_ALARM   = 'Door Open Too Long Alarm';
export const START_DOOR_OPEN        = 'Start Door Open Profile';
export const STOP_DOOR_OPEN         = 'Stop Door Open Profile';
export const DOOR_OPEN_REMOTE       = 'Door Open Remote';
export const DOOR_OPEN_NORMAL       = 'Door Mode Normal';
export const DOOR_MODE_OPEN         = 'Door Mode Open';
export const DOOR_MODE_CLOSED       = 'Door Mode Closed';
export const DOOR_MODE_EMA          = 'Door Mode EMA Alarm Active';

//EMA
export const EMA_SYSTEM                   = 'Try to arm System';
export const EMA_SYSTEM_ARMED             = 'System armed';
export const EMA_FAILED_SYSTEM            = 'Failed to arm System';
export const EMA_FAILED_NOT_READY_SYSTEM  = 'Failed to arm System Not Ready';
export const EMA_DISARM_SYSTEM            = 'Try to disarm System';
export const EMA_SYSTEM_SWITCH            = 'Try to disarm System, already switched';
export const EMA_SYSTEM_DISARMED          = 'System disarmed';
export const EMA_SYSTEM_DISARMED_FAILED   = 'Failed to disarm System';
export const EMA_ACCESS_GRANTED           = 'Ema Access Granted';

//READER
export const READERS          = 'Readers'
export const READER_ONLINE    = 'Reader Online';
export const READER_OFFLINE   = 'Reader Offline';
export const READER_BADGE     = 'Reader Badge';
export const READER_SABOTAGE  = 'Reader Sabotage';
export const READER_BATTERY   = 'Reader Battery Warning';
export const READER_COUPLING  = 'Coupling Error';
export const READER_KNON_IN   = 'Knob Teach IN';
export const READER_KNOB_OUT  = 'Knob Teach Out';
export const READER_TEACH     = 'Knob Teach In/Out Error';

//SERVICE
export const SERVICE_STARTED   = 'System Started';
export const SERVICE_LOGIN     = 'Application Login';
export const SERVICE_SHUTDOWN  = 'Shutdown';
export const SERVICE_DATE_TIME = 'DateTime Change';
export const SERVICE_EXIT      = 'Request To Exit';

//SDDL
export const SDDL_START       = 'Start Person Import';
export const SDDL_END         = 'End Person Import';
export const SDDL_ADD         = 'Person Import ADD';
export const SDDL_DEL         = 'Person Import DEL';
export const SDDL_CHG         = 'Person Import CHG';
export const SDDL_ERR         = 'person Import ERR';

//IMPORT EXPORT
export const DB_IMPORT        = 'DB Import started';
export const DB_EXPORT        = 'DB Export started';

//UPDATE DAEMON
export const PACKAGE_UPDATE     = 'Package Update';
export const DOWNLOAD_ZIP_FILE  = 'Download Zip File';
export const UNZIP_PACKAGE      = 'Unzip Package';
export const READER_UPDATE      = 'Readers Update';
export const TWN4_UPDATE        = 'TWN4 Update';
export const APPLICATION_UPDATE = 'Application Update';

//EVENTS CONSTANT

export const THIS_WEEK           = 'thisWeek';
export const THIS_MONTH          = 'thisMonth';
export const THIS_YEAR           = 'thisYear';
export const TODAY               = 'today';
export const DATE                = 'Date';
export const HOURS               = 'Hours';

export const OFF                 = 'OFF';
export const CONTAINER           = 'container';
export const ACCESS_DENIED       = 'Access Denied';
export const ACCESS_POINTS_EVENT = 'access points';
export const NUMBER_EVENTS       = 'Number of Events';

export const CONTROLLER_OFFLINE    = 'controllerOffline';
export const OPEN_SYNC_COMMANDS    = 'openSyncCommands';
export const AVAILABLE_CREDENTIALS = 'availableCredentials';
export const USER_INVALID_DATE  = 'usersWithInvalid';
export const INVALID_CREDENTIALS   = 'invalidCredentials';

export const DATE_CREATED        = 'dateCreated';
export const DATE_CREATE         = 'dateCreate';
export const SUBTYPE             = 'subType';
export const SUB_TYPE            = 'sub_type';
export const TYPE                = 'type';

export const STATUS              = 'Status';
export const GRANTED             = 'granted';

export const ALL_TYPES           = 'allTypes';
export const WEEK                = 'week';
export const MONTH               = 'month';
export const MONTHS              = 'months';
export const YEAR                = 'year';
export const DAY                 = 'day';
export const DAY_FORMAT          = 'ddd';
export const MONTH_FORMAT        = 'MMM DD';
export const YEAR_FORMAT         = 'MMM';
export const TODAY_FORMAT        = 'ddd HH';

//STATES
export const BW   = {name: 'Baden Württemberg', state: 'BW'};
export const BY   = {name: 'Bayern', state: 'BY'};
export const BE   = {name: 'Berlin', state: 'BE'};
export const BB   = {name: 'Brandenburg', state: 'BB'};
export const HB   = {name: 'Bremen', state: 'HB'};
export const HH   = {name: 'Hamburg', state: 'HH'};
export const HE   = {name: 'Hessen', state: 'HE'};
export const MV   = {name: 'Mecklenburg-Vorpommern', state: 'MV'};
export const NI   = {name: 'Niedersachsen', state: 'NI'};
export const NW   = {name: 'Nordrhein-Westfalen', state: 'NW'};
export const RP   = {name: 'Rheinland Pfalz', state: 'RP'};
export const SL   = {name: 'Saarland', state: 'SL'};
export const SN   = {name: 'Sachsen', state: 'SN'};
export const ST   = {name: 'Sachsen-Anhalt', state: 'ST'};
export const SH   = {name: 'Schleswig Holstein', state: 'SH'};
export const TH   = {name: 'Thüringen', state: 'TH'};

//Events Filter Types
export const Filter_TYPES    = [
                                  ALL,
                                  DOOR,
                                  READER,
                                  ACCESS_CHECK,
                                  EMA,
                                  SERVICE,
                                  REMOTE_CONTROL,
                                  IMPORT_EXPORT,
                                  SDDL,
                                  UPDATE_DAEMON_TYPE,
                                  DEVICE_REQUEST
                                ];

//Events Subtypes
export const ACCESS_CHECK_VALUES    = [
                                        ACCESS_DENIED,
                                        ACCESS_GRANTED
                                      ];
export const DOOR_VALUES            = [
                                        FORCE_OPEN,
                                        FORCE_OPEN_ARMED,
                                        DOOR_OPEN,
                                        DOOR_CLOSED,
                                        DOOR_OPEN_PRE_ALARM,
                                        DOOR_OPEN_LONG_ALARM,
                                        START_DOOR_OPEN,
                                        STOP_DOOR_OPEN,
                                        DOOR_OPEN_REMOTE,
                                        DOOR_OPEN_NORMAL,
                                        DOOR_MODE_OPEN,
                                        DOOR_MODE_CLOSED,
                                        DOOR_MODE_EMA
                                      ];
export const EMA_VALUES             = [
                                        EMA_SYSTEM,
                                        EMA_SYSTEM_ARMED,
                                        EMA_FAILED_SYSTEM,
                                        EMA_FAILED_NOT_READY_SYSTEM,
                                        EMA_DISARM_SYSTEM,
                                        EMA_SYSTEM_SWITCH,
                                        EMA_SYSTEM_DISARMED,
                                        EMA_SYSTEM_DISARMED_FAILED,
                                        EMA_ACCESS_GRANTED
                                      ];
export const READER_VALUES          = [
                                        READER_ONLINE,
                                        READER_OFFLINE,
                                        READER_BADGE,
                                        READER_SABOTAGE,
                                        READER_BATTERY,
                                        READER_COUPLING,
                                        READER_KNON_IN,
                                        READER_KNOB_OUT,
                                        READER_TEACH
                                      ];
export const SERVICE_VALUES         = [
                                        SERVICE_STARTED,
                                        SERVICE_LOGIN,
                                        SERVICE_SHUTDOWN,
                                        SERVICE_DATE_TIME,
                                        SERVICE_EXIT,
                                      ];
export const SDDL_VALUES            = [
                                        SDDL_START,
                                        SDDL_END,
                                        SDDL_ADD,
                                        SDDL_DEL,
                                        SDDL_CHG,
                                        SDDL_ERR,
                                      ];
export const IMPORT_EXPORT_VALUES   = [
                                        DB_IMPORT,
                                        DB_EXPORT
                                      ];
export const UPDATE_DAEMON          = [
                                        PACKAGE_UPDATE,
                                        DOWNLOAD_ZIP_FILE,
                                        UNZIP_PACKAGE,
                                        READER_UPDATE,
                                        TWN4_UPDATE,
                                        APPLICATION_UPDATE
                                      ];

//UPDATE DAEMON

// States
export const STATE_VALUES    = [
                                  BW,
                                  BY,
                                  BB,
                                  BE,
                                  HB,
                                  HH,
                                  HE,
                                  MV,
                                  NI,
                                  NW,
                                  RP,
                                  SL,
                                  SN,
                                  ST,
                                  SH,
                                  TH
                                ];


//EVENTS
export const INFO_ACCESS_POINT       = 'Access Point';
export const INFO_READER             = 'Reader';
export const INFO_CREDENTIAL_NUMBER  = 'Credential Number';
export const INFO_ADDITIONAL         = 'Additional';
export const INFO_DEVICE             = 'Device';
export const INFO_SERIAL_NUMBER      = 'Serial Number'
export const INFO_RESPONSE_CODE      = 'Overall Response Code'
export const RESET                   = 'Reset'

// CONTROLLER
export const MESSAGE_TYPE_FUNCTION  = 'function';
export const CONTROLLER_UPDATE      = 'packageupdate';
export const CONTROLLER_REBOOT      = 'reboot';
export const CONTROLLER_CLEAN_UP    = 'cleanup_clientdata';
export const CONTROLLER_REBUILD     = 'cleanup_clientdata';
export const ONLINE                 = 'ONL';
export const OFFLINE                = 'OFF';
export const IGNORED                = 'IGN';
export const NEW                    = 'NEW';
export const APPROVED               = 'APP';
export const APPROVE                = 'Approve';
export const DECLINE                = 'Decline';
export const ONLINE_STATUS          = 'Online';
export const OFFLINE_STATUS         = 'Offline';
export const DECLINED_STATUS        = 'Declined Controller';
export const DEVICE                 = 'device';
export const CONTROLLER_DEVICE_TYPE = Object.freeze({
  SECPASS : 'Secpass',
  X2CPASS : 'X2CPass'
});
export const CONTROLLER_HOUSING     = Object.freeze({
  NINETEENINCHRACK : '19INCHRACK',
});

// CONTROLLER DETAILS
export const DATA_DISK              = 'dataDisk';
export const SYSTEM_DISK            = 'systemDisk';
export const RAM                    = 'RAM';
export const CPU                    = 'CPU';

// DEFAULT ROLES
export const DEFAULT_ROLES = Object.freeze({
  SYSTEM_ADMIN        : 'System Admin',
  DEVICE_ADMIN        : 'Device Admin',
  OPERATOR            : 'Operator',
  PERMISSION_MANAGER  : 'Permission Manager',
  EVENT_VIEWER        : 'Event Viewer',
});

export const SYSTEM_ADMIN           = 'System Admin';
export const DEVICE_ADMIN           = 'Device Admin';
export const OPERATOR               = 'Operator';
export const PERMISSION_MANAGER     = 'Permission Manager';
export const EVENT_VIEWER           = 'Event Viewer';

export const SAVE                               = 'save';
export const DONE                               = 'done';
export const EXISTING_CREDENTIAL                = 'existingCredential';
export const NEW_CREDENTIAL                     = 'newCredential';
export const CREATE                             = 'create';
export const SEARCH                             = 'searchBox';
export const YES_DEACTIVATE                     = 'yesDeactivate';
export const NEXT                               = 'next';

//Params
export const UNREFERENCED            = 'unreferenced';
export const INVALID                 = 'invalid';
export const AVAILABLE               = 'available';
export const VALID_FROM              = 'valid_from';
export const VALID_UNTIL             = 'valid_until';

export const VIEW              = 'View';
export const ADD               = 'Add';
export const UPDATE            = 'Update';
export const MAINTAIN          = 'maintain';
export const ONBOARD           = 'Onboard'
export const ASSIGN            = 'assign';
export const READ              = 'Read';
//Actions
export const ACTION_ADD              = 'add';
export const ACTION_CREATE           = 'create';
export const ACTION_UPDATE           = 'update';
export const ACTION_VIEW             = 'view';
export const ACTION_DELETE           = 'delete';
export const ACTION_EXIT             = 'exit';

//Property
export const LOCATION               = 'location'; 
export const PERSON                  = 'person'; 

//request
export const REQ_SCHED_ADD          = 'scheduleAdd';
export const REQ_HOLIDAY_ADD        = 'holidayAdd';
export const REQ_SCHED_HOLILDAY_ADD = 'scheduleAndHolidayAdd';
export const REQ_AP_SWITCHED        = 'controlPointSwitchedAdd';
export const REQ_AP_ADD             = 'controlPointAdd';
export const REQ_UNSYNC             = 'unsync';
export const REQ_PROFILE_ADD        = 'profileAdd';

export const EXPAND_ALL             = 'EXPAND_ALL';
export const CONTRACT_ALL           = 'CONTRACT_ALL';

//Permissions
export const PERMISSION_READ              = 'read';
export const PERMISSION_MAINTAIN          = 'maintain';
export const PERMISSION_ASSIGN            = 'assign';

// Projections
export const PROJECTION = Object.freeze({
  ACCESS_POINT_CONTROLLER : 'accessPointControllerProjection',
  ACCESS_POINTS           : 'accessPointsProjection',
  AREAS_WITH_NESTED_CHILD : 'areaWithNestedChildProjection',
  LOCATION                : 'locationProjection',
  LOCATION_LEVELS         : 'locationLevelsProjection',
  CONTROLLER              : 'controllerProjection',
  CONTROLLERS             : 'controllersProjection',
  CREDENTIAL              : 'credentialProjection',
  CREDENTIALS             : 'credentialsProjection',
  EVENTS                  : 'eventsProjection',
  HOLIDAYS                : 'holidaysProjection',
  PERSON                  : 'personProjection',
  PERSONS                 : 'personsProjection',
  PROFILE                 : 'profileProjection',
  PROFILES                : 'profilesProjection',
  ROLES                   : 'rolesProjection',
  SCHEDULES               : 'schedulesProjection',
});

export const WSTOPIC = Object.freeze({
  EVENTS            : '/topic/events',
  CONTROLLER        : '/topic/controllers',
  DEVICE_REQUEST    : '/topic/device-request',
  ERROR             : '/queue/errors',
  OVERALL_MISC      : '/topic/controllers/overallMisc',
  OVERALL_STATE     : '/topic/controllers/overallState',
  CONTROLLER_STATUS : '/topic/controllers/status',
  NEW_CONTROLLER    : '/topic/controllers/newController',
  NEW_ACCESSPOINT   : '/topic/controllers/newAccessPoints',
})

//Count Nouns
export const MULTIPLE            = 'multiple';
export const SINGLE              = 'single';

//Component Names
export const APPBAR = 'appBar';
export const DRAWER = 'drawer';

// Keycloak headers
export const KEYCLOAK_HEADERS = Object.freeze({
  GRANT_TYPE                        : 'urn:ietf:params:oauth:grant-type:uma-ticket',
  PAC_SERVER_AUDIENCE               : 'pacaas-pac-server',
  DEVICE_MANAGEMENT_SERVER_AUDIENCE : 'pacaas-device-management-server',
  RESPONSE_MODE                     : 'permissions',
});

// Keycloak actions
export const KEYCLOAK_ACTIONS = Object.freeze({
  UPDATE_PASSWORD: 'UPDATE_PASSWORD'
})

// Language
export const LANGUAGE_EN = 'en';
export const LANGUAGE_DE = 'de';

export const PAGINATION = Object.freeze({
  MAX_SIZE : 250
});

export const MAX_CHARACTER_LIMIT = Object.freeze({
  TEXT_FIELD : 255,
  PIN_FIELD  : 8
});

export const HTTP            = 'http';
export const HTTPS           = 'https';
export const WSS             = 'wss';
export const DEVMAN_PATH     = '/api/devman';
export const CONTROLLER_PATH = '/dev/ws';

export const ADMINISTRATOR_ATTRIBUTES = Object.freeze({
  ADMINISTRATOR: 'administrator',
  QUERY: 'type:administrator'
});

export const DASHBOARD_CHIP = Object.freeze({
  ACCESS_DENIED_CHIP        : 'accessDenied',
  AVAILABLE_CREDENTIAL_CHIP : 'availableCredentials',
  INVALID_CREDENTIAL_CHIP   : 'invalidCredentials',
  USER_WITH_INVALID_CHIP    : 'usersWithInvalid',
});

export const USERNAME = 'username';

export const KEYCLOAK_REALM_PERMISSIONS = Object.freeze({
  VIEW_USERS : 'view-users'
});

export const PAGE_TYPE = Object.freeze({
  VIEW  : 'view',
  UPDATE: 'update',
  CREATE: 'create',
});

export const SIDEBAR = 'sidebar';

export const ENTITY = Object.freeze({
  USER  : 'user',
  USERS : 'users'
})

export const LOCATIONS_TEXT_FIELD_LABEL = Object.freeze({
  LOCATION_NAME: 'locations-page.locationName',
  AREA_NAME    : 'locations-page.areaName'
});

export const LOCATIONS_SUCCESS_TOASTER = Object.freeze({
  TITLE                      : 'success',
  SEVERITY                   : 'success',
  DELETE_AREA_DESCRIPTION    : 'locations-page.deleteAreaSuccess',
  UPDATE_AREA_DESCRIPTION    : 'locations-page.updateAreaSuccess',
  CREATE_AREA_DESCRIPTION    : 'locations-page.createAreaSuccess',
  DELETE_LOCATION_DESCRIPTION: 'locations-page.deleteLocationSuccess',
  UPDATE_LOCATION_DESCRIPTION: 'locations-page.updateLocationSuccess',
  CREATE_LOCATION_DESCRIPTION: 'locations-page.createLocationSuccess'
});

export const LOCATIONS_ERROR_TOASTER = Object.freeze({
  TITLE                      : 'error',
  SEVERITY                   : 'error',
  GET_AREA_DESCRIPTION       : 'locations-page.getAreaError',
  GET_LOCATION_DESCRIPTION   : 'locations-page.getLocationError',
  DELETE_AREA_DESCRIPTION    : 'locations-page.deleteAreaError',
  DELETE_AREA_LOCATION_DESCRIPTION : 'locations-page.deleteAreaLocationError',
  UPDATE_AREA_DESCRIPTION    : 'locations-page.updateAreaError',
  CREATE_AREA_DESCRIPTION    : 'locations-page.createAreaError',
  DELETE_LOCATION_DESCRIPTION      : 'locations-page.deleteLocationError',
  DELETE_LOCATION_SINGLE_ENTITY    : 'locations-page.deleteLocationWithSingleEntityError',
  DELETE_LOCATION_MULTIPLE_ENTITIES: 'locations-page.deleteLocationWithMultipleEntitiesError',
  UPDATE_LOCATION_DESCRIPTION: 'locations-page.updateLocationError',
  CREATE_LOCATION_DESCRIPTION: 'locations-page.createLocationError'
});

export const LOCATION_PROJECTIONS = Object.freeze({
  NESTER_PARENT_CHILDREN: 'areaWithNestedParentAndChildProjection',
  NESTER_CHILDREN       : 'areaWithNestedChildProjection',
  NESTER_PARENT         : 'areaWithNestedParentProjection',
  PARENT_AREA           : 'areaWithNestedChildProjection',
  NESTED_PARENT_LOCATION: 'locationProjection',
});

export const AUDIT_LOG_TRANSLATION_KEY = Object.freeze({
  CREATEA_INITIAL_LOCATION: 'audit-log.createInitialLocation',
  CREATE_LOCATION         : 'audit-log.createLocation',
  CREATE_AREA             : 'audit-log.createArea',
  USER_ASSIGN             : 'audit-log.userAssign',
  CREDENTIAL_ASSIGN       : 'audit-log.credentialAssign',
  ROLE_ASSIGN             : 'audit-log.roleAssign',
  PROFILE_ASSIGN          : 'audit-log.profileAssign',
  SCHEDULE_ASSIGN         : 'audit-log.scheduleAssign',
  HOLIDAY_ASSIGN          : 'audit-log.holidayAssign',
  CONTROLLER_ASSIGN       : 'audit-log.controllerAssign',
  ACCESSPOINT_ASSIGN      : 'audit-log.accessPointAssign',
  USER_UNASSIGNED         : 'audit-log.userUnassigned',
  CREDENTIAL_UNASSIGNED   : 'audit-log.credentialUnassigned',
  ROLE_UNASSIGNED         : 'audit-log.roleUnassigned',
  PROFILE_UNASSIGNED      : 'audit-log.profileUnassigned',
  SCHEDULE_UNASSIGNED     : 'audit-log.scheduleUnassigned',
  HOLIDAY_UNASSIGNED      : 'audit-log.holidayUnassigned',
  CONTROLLER_UNASSIGNED   : 'audit-log.controllerUnassigned',
  ACCESSPOINT_UNASSIGNED  : 'audit-log.accessPointUnassigned',
  UPDATE_AREA             : 'audit-log.updateArea',
  UPDATE_LOCATION         : 'audit-log.updateLocation',
  DELETE_AREA             : 'audit-log.deleteArea',
  DELETE_LOCATION         : 'audit-log.deleteLocation'
});

export const AUDIT_LOG_AREA_OPERATIONS = Object.freeze({
  1: 'CREATE_AREA',
  2: 'UPDATE_AREA',
  3: 'DELETE_AREA',
});

const AUDIT_ASSIGNED_ENTITIES = Object.freeze({
  USER       : 'USER_ASSIGN',
  CREDENTIAL : 'CREDENTIAL_ASSIGN',
  ROLE       : 'ROLE_ASSIGN',
  PROFILE    : 'PROFILE_ASSIGN',
  SCHEDULE   : 'SCHEDULE_ASSIGN',
  HOLIDAY    : 'HOLIDAY_ASSIGN',
  CONTROLLER : 'CONTROLLER_ASSIGN',
  ACCESSPOINT: 'ACCESSPOINT_ASSIGN',
});

const AUDIT_UNASSIGNED_ENTITIES = Object.freeze({
  USER       : 'USER_UNASSIGNED',
  CREDENTIAL : 'CREDENTIAL_UNASSIGNED',
  ROLE       : 'ROLE_UNASSIGNED',
  PROFILE    : 'PROFILE_UNASSIGNED',
  SCHEDULE   : 'SCHEDULE_UNASSIGNED',
  HOLIDAY    : 'HOLIDAY_UNASSIGNED',
  CONTROLLER : 'CONTROLLER_UNASSIGNED',
  ACCESSPOINT: 'ACCESSPOINT_UNASSIGNED',
});

export const AUDIT_LOG_LOCATION_OPERATIONS = Object.freeze({
  1: 'CREATE_LOCATION',
  2: 'UPDATE_LOCATION',
  3: 'DELETE_LOCATION',
  4: AUDIT_ASSIGNED_ENTITIES,
  5: AUDIT_UNASSIGNED_ENTITIES
});

export const AUDIT_LOG_ERROR_TOASTER = Object.freeze({
  TITLE      : 'error',
  SEVERITY   : 'error',
  DESCRIPTION: 'audit-log.getAuditLogError',
});

export const SETTINGS_CONFIGURATION = Object.freeze({
  LANGUAGE                 : 'language',
  VALIDITY_DATES           : 'validityDates',
  EVENT_CONFIGURATION      : 'eventConfiguration',
  CONTROLLER_CONFIGURATION : 'controllerConfiguration'
});

export const CHIP_COLOR = Object.freeze({
  LIGHT  : 'light',
  NEUTRAL: 'neutral',
  OFFLINE: 'offline',
  ONLINE : 'online',
  OUTLINE: 'outline',
  PLAIN  : 'plain' 
});

export const READ_MODE = Object.freeze({
  PIN       : 'reader.pin',
  BADGE     : 'reader.badge',
  BADGEORPIN: 'reader.badgeOrPin',
  PINBADGE  : 'reader.badgeOrPin',
  BADGEPIN  : 'reader.badgeOrPin'
});

export const EXPAND_COLLAPSE = Object.freeze({
  EXPAND_ALL    : 'expandCollapse.expandAll',
  CONTRACT_ALL  : 'expandCollapse.contractAll',
  EXPAND        : 'EXPAND_ALL',
  CONTRACT      : 'CONTRACT_ALL',
});

export const CHIP_COMPONENT = Object.freeze({
  READER       : 'reader',
  ACCESS_POINT : 'accessPoint',
  SYNC         : 'sync',
  COMMON       : 'common',
});