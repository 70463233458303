import { ReactKeycloakProvider } from '@react-keycloak/web';
import LocationContextProvider from './context/locationContext';
import AuthContextProvider from './context/authContext';
import EventsFilterProvider from './context/eventsFIlterContext';
import keycloak from './Keycloak';
import { setLocalStorageItem } from './utility/helper';

const onEvent = (event, error) => {
  console.log('onKeycloakEvent', event, error)
}

const onTokens = async (tokens) => {
  const { token } = tokens;
  // Store the token so that Axios can access it without context
  setLocalStorageItem('token', token);
  console.log('onKeycloakTokens', tokens);
}

const ProtectedResources = (props) => {
  const { children } = props;

  return (
    <ReactKeycloakProvider 
      authClient={keycloak}
      onEvent={onEvent}
      onTokens={onTokens}
    >
      <AuthContextProvider>
        <LocationContextProvider>
          <EventsFilterProvider>
            {
              children
            }
          </EventsFilterProvider>
        </LocationContextProvider>
      </AuthContextProvider>
    </ReactKeycloakProvider>
  )
}

export default ProtectedResources