import { faBars, faCalendarDay, faClock, faCog, faDoorOpen, faIdBadge, faIdCard, faMicrochip, faThLarge, faUser, faUserCog, faUserEdit, faUserFriends } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { AppBar, Avatar, Box, Button, Drawer, Grid, IconButton, List, ListItem, ListItemIcon, ListItemText, Menu, MenuItem, Modal, Toolbar, Tooltip, Typography } from '@material-ui/core';
import { TourProvider, useTour } from '@reactour/tour';
import clsx from 'clsx';
import Highcharts from 'highcharts';
import React, { useCallback, useContext, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useHistory } from 'react-router-dom';
import { AuthContext } from '../../context/authContext';
import { EventsFilterContext } from '../../context/eventsFIlterContext';
import { LocationContext } from '../../context/locationContext';
import { CLEAR_FILTER } from '../../reducer/eventsFilterReducer';
import { LOGOUT, SET_LOCATION_FILTER } from '../../reducer/locationReducer';
import { ACCESS_POINTS, ACCESS_POINTS_MODULE, ADMINISTRATORS, ADMINISTRATORS_MODULE, ADMINISTRATOR_ROLES, ADMINISTRATOR_ROLES_MODULE, APPBAR, CONTROLLERS, CONTROLLERS_MODULE, CREDENTIALS, CREDENTIALS_MODULE, DASHBOARD, DASHBOARD_MODULE, DRAWER, EVENTS, EVENTS_MODULE, GET, HOLIDAYS, HOLIDAYS_MODULE, LOCATION, LOCATIONS, LOCATIONS_MODULE, LOCATION_FILTER, PAGE_TYPE, POST, PROFILES, PROFILES_MODULE, ROLES, ROLES_MODULE, SCHEDULES, SCHEDULES_MODULE, SETTINGS, SETTINGS_MODULE, SIDEBAR, STANDARD_SCHEDULES, SYSTEM_ADMIN, SYSTEM_ROLES, USERS, USERS_MODULE } from '../../utility/constants';
import { getLocalStorageItem, setLocalStorageItem } from '../../utility/helper';
import images from "../../utility/images";
import HelpCenter from '../help-center';
import RedirectModal from '../redirect-modal';
import SelectItems from '../select-items';
import SubMenu from "../sub-menu";
import useStyles from './styles';

export const MENUS = [
  {
    name : DASHBOARD,
    label: DASHBOARD_MODULE,
    path : `/${DASHBOARD}`,
    icon : <FontAwesomeIcon icon={faThLarge} size="lg" />
  },
  // {
  //   name: TENANTS,
  //   path: `/${TENANTS}`,
  //   icon: <FontAwesomeIcon icon={faBuilding} size="lg" />
  // },
  {
    name : USERS,
    label: USERS_MODULE,
    path : `/${USERS}`,
    icon : <FontAwesomeIcon icon={faUser} size="lg" />
  },
  {
    name : CREDENTIALS,
    label: CREDENTIALS_MODULE,
    path : `/${CREDENTIALS}`,
    icon : <FontAwesomeIcon icon={faIdBadge} size="lg" />
  },
  {
    name : ROLES,
    label: ROLES_MODULE,
    path : `/${ROLES}`,
    icon : <FontAwesomeIcon icon={faUserCog} size="lg" />
  },
  {
    name : PROFILES,
    label: PROFILES_MODULE,
    path : `/${PROFILES}`,
    icon : <FontAwesomeIcon icon={faIdCard} size="lg" />
  },
  // {
  //   name: ACCESS_POINTS_MANAGEMENT,
  //   icon: <FontAwesomeIcon icon={faDoorOpen} size="lg" />,
  //   subMenu: [
  //     {
  //       name: ACCESS_POINTS,
  //       path: `/${ACCESS_POINT}`,
  //     },
  //     // {
  //     //   name: GROUPS,
  //     //   path: `/${GROUPS}`,
  //     // },
  //   ]
  // },
  {
    name : ACCESS_POINTS,
    label: ACCESS_POINTS_MODULE,
    path : `/${ACCESS_POINTS}`,
    icon : <FontAwesomeIcon icon={faDoorOpen} size="lg" />
  },
  {
    name: SCHEDULES,
    icon: <FontAwesomeIcon icon={faClock} size="lg" />,
    subMenu: [
      {
        name : STANDARD_SCHEDULES,
        label: SCHEDULES_MODULE,
        path : `/${SCHEDULES}`,
      },
      {
        name : HOLIDAYS,
        label: HOLIDAYS_MODULE,
        path : `/${HOLIDAYS}`,
      },
    ]
  },
  {
    name : EVENTS,
    label: EVENTS_MODULE,
    path : `/${EVENTS}`,
    icon : <FontAwesomeIcon icon={faCalendarDay} size="lg" />
  },
  {
    name : CONTROLLERS,
    label: CONTROLLERS_MODULE,
    path : `/${CONTROLLERS}`,
    icon : <FontAwesomeIcon icon={faMicrochip} size="lg" />
  },
  {
    name : ADMINISTRATORS,
    label: ADMINISTRATORS_MODULE,
    path : `/${ADMINISTRATORS}`,
    icon : <FontAwesomeIcon icon={faUserFriends} size="lg" />
  },
  {
    name : ADMINISTRATOR_ROLES,
    label: ADMINISTRATOR_ROLES_MODULE,
    path : `/${SYSTEM_ROLES}`,
    icon : <FontAwesomeIcon icon={faUserEdit} size="lg" />
  },
  {
    name : SETTINGS,
    label: SETTINGS_MODULE,
    path : `/${SETTINGS}`,
    icon : <FontAwesomeIcon icon={faCog} size="lg" />,
    subMenu: [
      {
        name : LOCATIONS,
        label: LOCATIONS_MODULE,
        path : `/${LOCATIONS}`,
      }
    ]
  },
];

const subMenu = (menu, handlePermissions, roles) => {
  const { subMenu, icon } = menu;
  const subMenuPermittedList = subMenu.filter(sub => handlePermissions(sub.label, GET));

  const isSystemAdmin = roles.includes(SYSTEM_ADMIN);

  if (!subMenuPermittedList.length) {
    return false;
  }
  
  if (subMenuPermittedList.length > 1) {
    return menu;
  }

  if (subMenuPermittedList[0]?.label === LOCATIONS_MODULE && !isSystemAdmin) {
    delete menu['subMenu'];
    return menu;
  } else {
    return {
      ...subMenuPermittedList[0],
      icon: icon
    }
  }
}

const handleMenuList = (handlePermissions, roles) => {
  const isSystemAdmin = roles.includes(SYSTEM_ADMIN);
  const filterMenu = MENUS.filter(menu => {
    if (menu.label === DASHBOARD_MODULE) {
      return true;
    }

    if (menu.subMenu?.length) {
      return subMenu(menu, handlePermissions, roles);
    }

    if (menu.label === ADMINISTRATOR_ROLES_MODULE && !isSystemAdmin) {
      return false;
    } else {
      return handlePermissions(menu.label, GET);
    }
  });

  const settings = filterMenu.find(menu => menu.name === SETTINGS);
  if (!settings) {
    const setting = MENUS.find(menu => menu.name === SETTINGS);
    const { subMenu, ...newSetting } = setting;
    return [...filterMenu, newSetting];
  }

  return filterMenu;
}

const MenuPartition = (props) => {
  const classes = useStyles();
  const { name } = props

  switch(name){
    case EVENTS                    :
    case DASHBOARD                 :
    case ADMINISTRATOR_ROLES       :
    case ROLES                     :
    case SCHEDULES                 :
    case CONTROLLERS               :
      return <Box className={classes.partition} />;
    default: return (null);
  }
}

const STEPS = [
  {
    selector: '.menu-list-1',
    content: '',
  },
  {
    selector: '.menu-list-2',
    content: '',
  }
]

const MainLayout = (props) => {
  const { children, handlePermissions } = props;
  const { state: locationState }  = useContext(LocationContext);
  const selectedLocation          = locationState?.selectedLocations ? locationState.selectedLocations : [];

  const classes = useStyles();

  const [open, setOpen]                          = useState(false);
  const [openTour, setOpenTour]                  = useState(false);
  const [step, setStep]                          = useState('');
  const [content, setContent]                    = useState('');
  const [locationObject, setLocationObject]      = useState(selectedLocation);

  const handleClose = () => {
    setOpen(false);
    setOpenTour(false);
  }

  const handleSetWizardContent = (index) => {
    setOpen(true);
    setOpenTour(true);
    setContent(index);
    setStep(index);
  }

  return (
    <>
      <Modal
        open={open}
        onClose={handleClose}
        className={classes.wizardModal}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
        hideBackdrop
      >
        <HelpCenter
          handleClose={handleClose}
          content={content}
          setContent={setContent}
          handlePermissions={handlePermissions}
        />
      </Modal>
      <TourProvider
        className={classes.popOver}
        maskClassName={classes.backdrop}
        steps={STEPS}
        showNavigation={false}
        showPrevNextButtons={false}
        showCloseButton={false}
        showBadge={false}
        disableInteraction={false}
        disableKeyboardNavigation={true}
      >
        <MainLayoutChildren
          children={children}
          step={step}
          openTour={openTour}
          handleSetWizardContent={handleSetWizardContent}
          handlePermissions={handlePermissions}
          locationObject={locationObject}
          setLocationObject={setLocationObject}
        />
      </TourProvider>
    </>
  )
}

const AppBarLead = (props) => {
  const { isCompactView, component, handleSetWizardContent, handleClickLogo, hasOnBoardingPermission } = props;
  const classes = useStyles();
  const { t }   = useTranslation();

  return (
    <>
      {
        (isCompactView && component === APPBAR) || (!isCompactView && component === DRAWER) ?
          <img src={images.SESAMSEC_LOGO} id="mainLayoutSesamsecLogo" className={classes.logo} alt="elatec-logo" onClick={handleClickLogo}></img>
        :
          <>
            <Grid item 
              lg={4} 
              className={clsx({
                  'hidden'                  : !hasOnBoardingPermission,
                  [classes.wizardContainer] : component === DRAWER
                })
              }
            >
              <Button 
                variant="outlined"
                id="mainLayoutOnboardNewUser"
                className={classes.wizardButton}
                onClick={() => handleSetWizardContent(0)}
              >
                <img className={classes.wizardImage} src={images.ONBOARD_NEW_PERSON_SVG} alt=""/>
                <Typography className={classes.wizardTitle}>{t('onboardNewUser')}</Typography>
              </Button>
            </Grid>
            <Grid item lg={4} className={clsx(!hasOnBoardingPermission && 'hidden')}>
              <Button 
                variant="outlined"
                id="mainLayoutReplaceCredentials"
                className={classes.wizardButton}
                onClick={() => handleSetWizardContent(1)}
              >
                <img className={classes.wizardImage2} src={images.REPLACE_LOST_CREDENTIAL_SVG} alt=""/>
                <Typography className={classes.wizardTitle}>{t('replaceLostCredential')}</Typography>
              </Button>
            </Grid>
          </> 
      }
    </>
  )
}

const MainLayoutChildren = (props) => {
  const { children, openTour, step, handleSetWizardContent, handlePermissions, locationObject, setLocationObject } = props;
  
  const classes = useStyles();
  const history = useHistory();
  const { t }   = useTranslation();

  const { dispatch : eventsDispatch }      = useContext(EventsFilterContext);
  const { dispatch : locationDispatch }   = useContext(LocationContext);
  const {state, logout} = useContext(AuthContext); 
  const administrator = state.administrator;
  const { roles } = administrator;

  const { setIsOpen, setCurrentStep } = useTour();

  const [anchorEl, setAnchorEl]                               = useState(null);
  const [sidebar,setSidebar]                                  = useState(getLocalStorageItem(SIDEBAR));
  const [redirectModal, setRedirectModal]                     = useState(false);
  const [isHoverSidebar, setIsHoverSideBar]                   = useState(!getLocalStorageItem(SIDEBAR));
  const [redirectMessage]                                     = useState('');
  const [hasMultipleLocations, setHasMultipleLocations]       = useState(false);
  const [isCompactView, setIsCompactView]                     = useState(false);

  const hasOnBoardingPermission = handlePermissions(USERS_MODULE, POST) && handlePermissions(CREDENTIALS_MODULE, POST);

  const menuList = handleMenuList(handlePermissions, roles);

  const unfilteredModules = [ADMINISTRATORS, SYSTEM_ROLES, SETTINGS, LOCATIONS];

  const showLocationFilter = hasMultipleLocations &&
    handlePermissions(LOCATIONS_MODULE, GET) &&
    unfilteredModules.every(module => !history.location.pathname.includes(module));

  const [windowDimension, setWindowDimension]                 = useState({
    windowWidth : window.innerWidth
  });

  useEffect(() => {
    window.addEventListener('resize', updateWindowDimension);

    if (windowDimension.windowWidth < 1280) {
      if (!isCompactView) {
        setIsHoverSideBar(false);
        setSidebar(true);
        setIsCompactView(true);
      }
    } else {
      if (isCompactView) {
        setIsHoverSideBar(false);
        setSidebar(true);
        setIsCompactView(false);
      }
    }

    return() => {
      window.removeEventListener('resize', updateWindowDimension);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [windowDimension]);

  useEffect(() => {
    setLocalStorageItem('sidebar',sidebar);
    administrator.locations.length > 1 ? setHasMultipleLocations(true) : setHasMultipleLocations(false);
  }, [sidebar, administrator.locations]);

  useEffect(() => {
    setTimeout(() => {
      for (const chart of Highcharts.charts) {
        chart !== undefined && chart.reflow();
      }
    }, 250);
  }, [sidebar]);

  useEffect(() => {
    setIsOpen(openTour);
    setCurrentStep(step);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [step, openTour]);

  const updateWindowDimension = () => {
    setWindowDimension({
      windowWidth  : window.innerWidth
    })
  }

  const showSidebar = () => {
    if (isHoverSidebar) {
      setSidebar(!sidebar);
      setIsHoverSideBar(false);
    } else {
      setSidebar(false);
      setIsHoverSideBar(true);
    }
  }

  const getPathIndex = useCallback(() => {
    if (history.location.pathname.includes('myaccount')) {
      return;
    }
    return menuList.findIndex(menu => history.location.pathname.includes(menu.path));
  }, [history.location.pathname, menuList])

  const getPathSubIndex = useCallback(() => {
    if (history.location.pathname.includes('myaccount')) {
      return;
    }
    const subMenuList = menuList.find(list => list.subMenu);

    return subMenuList?.subMenu.findIndex(menu => history.location.pathname.includes(menu.path));
  }, [history.location.pathname, menuList]);

  const [selectedSubIndex, setSelectedSubIndex] = useState(getPathSubIndex());
  const [selectedIndex, setSelectedIndex]       = useState(getPathIndex());
  
  useEffect(() => {

    setSelectedIndex(getPathIndex());
  }, [selectedIndex, history.location.pathname, getPathIndex]);

  useEffect(() => {
    setSelectedSubIndex(getPathSubIndex());
  }, [selectedSubIndex, history.location.pathname, getPathSubIndex]);

  const handleListItemClick = (index) => {
    const pageType = Object.values(PAGE_TYPE).find(page => window.location.href.includes(page));
    if (selectedIndex === index && !pageType) {
        return
    }

    const pathname    = menuList[index].path;
    const isEventView = window.location.href.includes(`${EVENTS}/view`);
    
    if (!isEventView) {
      eventsDispatch({ type: CLEAR_FILTER });
    } 

    const basePath   = pathname.replace('/', '');
    
    const pages = {
      DASHBOARD,
      SETTINGS,
      ADMINISTRATORS,
      LOCATION,
      SYSTEM_ROLES,
    };

    const currentPage = Object.values(pages).find(page => pathname.includes(page));

    const search = currentPage ? '' : `?${basePath}=all&page=0&size=10`;
    
    const locationState = {
      prevLocation: window.location.href
    }

    const location = {
      pathname  : pathname,
      search    : search,
      state     : isEventView && locationState
    }

    history.push(location);
    setSelectedSubIndex(null);
    setSelectedIndex(index);
  };

  const myAccount = () => {
    history.push('/myaccount');
  }

  const signOut = () => {
    logout();
    
    setLocalStorageItem('roles', []);
    locationDispatch({
      type: LOGOUT
    });
  }

  const handleClickLogo = () => {
    history.push(`/${DASHBOARD}`);
  }

  const handleCloseRedirectModal = () => {
    setRedirectModal(false);
  }

  const onMouseEnterSidebar = () => {
    if (isHoverSidebar) {
      return;
    }
    setSidebar(false);
  }

  const onMouseLeaveSidebar = () => {
    if (isHoverSidebar && sidebar === false) {
      return;
    }
    setSidebar(true);
  }

  const handleLocationChange = (locations, area) => {
    setLocationObject(locations);

    const locationIds = locations.map(location => location.locationId);
    locationDispatch({
      type: SET_LOCATION_FILTER,
      payload: {
        selectedLocationIds: locationIds,
        selectedLocations: locations,
        selectedArea: area
      }
    });
  };

  return (
    <div className={classes.root}>
      <RedirectModal
        onClose={handleCloseRedirectModal}
        open={redirectModal}
        redirectToLogin={signOut}
        title={'Oops!'}
        id="mainLayoutRedirectToLogin"
        description={redirectMessage}
        buttonText={t('backToLogin')}
        image={images.NO_PERMISSION_SVG}
        span={' support@sesamsec.com.'}
      />
      <AppBar position="absolute" 
        className={`${clsx(classes.appBar, {[classes.appBarShift]: !sidebar && !isCompactView, [classes.appBarCompactView]: isCompactView,})} ${classes.indexApp}`}>
        <Toolbar>
          <Grid container className={clsx(hasOnBoardingPermission ? classes.toolbar : classes.toolbarEnd )} spacing={3} maxWidth="xl">
            <AppBarLead
              isCompactView={isCompactView}
              component={APPBAR}
              handleSetWizardContent={handleSetWizardContent}
              handleClickLogo={handleClickLogo}
              hasOnBoardingPermission={hasOnBoardingPermission}
            />
            <Grid item className={classes.toolbarRight} lg={4}>
              <Grid item className={showLocationFilter ? classes.locationFilter : 'hidden'} xs={4}>
                <SelectItems
                  name={LOCATION_FILTER}
                  id="mainLayoutGlobalLocation"
                  onChange={handleLocationChange}
                  selectedItems={locationObject}
                  handlePermissions={handlePermissions}
                />
              </Grid>
              <Typography id="mainLayoutUserName" component="h1" variant="body2" color="primary" noWrap>
                {t('hi')} <span className='bold first-step'>{administrator?.firstName}</span>
              </Typography>
              <IconButton id="mainLayoutUserAvatar" className={classes.avatarButton} color="secondary" aria-controls="simple-menu" aria-haspopup="true" onClick={event => setAnchorEl(event.currentTarget)}>
                <Avatar className={classes.avatar}>{administrator?.firstName?.slice(0, 1).toUpperCase()}</Avatar>
              </IconButton>
              <Menu
                id="simple-menu"
                anchorEl={anchorEl}
                keepMounted
                open={Boolean(anchorEl)}
                onClose={() => setAnchorEl(null)}
                onClick={() => setAnchorEl(null)}
                anchorOrigin={{
                  vertical: 'bottom',
                  horizontal: 'center',
                }}
                transformOrigin={{
                  vertical: 'top',
                  horizontal: 'center',
                }}
                getContentAnchorEl={null}
                >
                <MenuItem id="mainLayoutMyAccount" onClick={myAccount}>{t('myAccount')}</MenuItem>
                <MenuItem id="mainLayoutLogOut" onClick={signOut}>{t('logOut')}</MenuItem>
              </Menu>
            </Grid>
          </Grid>
        </Toolbar>
      </AppBar>
      <Drawer
        id="mainLayoutMainMenu"
        onMouseEnter={onMouseEnterSidebar}
        onMouseLeave={onMouseLeaveSidebar}
        variant="permanent"
        classes={{
          paper: clsx({
            [classes.drawerPaperHide]            : sidebar  && !isCompactView,
            [classes.drawerPaper]                : !sidebar && !isCompactView,
            [classes.drawerPaperHideCompactView] : sidebar  && isCompactView,
            [classes.drawerPaperCompactView]     : !sidebar && isCompactView,
          }, 'MuiPaper-elevation4'),
        }}
        >
        <List className={classes.list}>
          <ListItem style={{cursor:'pointer'}}>
            <Tooltip title={sidebar ? t('showMenu'):''}>
              <ListItemIcon>
                <FontAwesomeIcon icon={faBars} size="lg" onClick={showSidebar} />
              </ListItemIcon>
            </Tooltip>
            <AppBarLead
              isCompactView={isCompactView}
              component={DRAWER}
              handleSetWizardContent={handleSetWizardContent}
              handleClickLogo={handleClickLogo}
              hasOnBoardingPermission={hasOnBoardingPermission}
            />
          </ListItem>
          {
            menuList.map((menu, index) => {
              const hasSubmenu = menu.subMenu?.length;
              const pathIndex = menu.subMenu?.findIndex(menu => history.location.pathname.includes(menu.path));
              const isCurrentPage = history.location.pathname.includes(menu.path);

              return (
                hasSubmenu ?
                    <div  key={'expansion-panel-' + index}>
                      <SubMenu 
                        index={index}
                        menu={menu}
                        isCompactView={isCompactView}
                        setSidebar={setSidebar}
                        selectedIndex={selectedIndex}
                        setSelectedIndex={setSelectedIndex}
                        menuList={menuList}
                        sidebar={sidebar}
                        selectedSubIndex={selectedSubIndex}
                        pathIndex={pathIndex}
                        isCurrentPage={isCurrentPage}
                        handleListItemClick={handleListItemClick}
                      />
                      <Box className={classes.partition} />
                    </div>
                  :
                    <Box key={'box-panel-' + index} className={clsx(`menu-list-${index}`)}>
                      <Tooltip title={sidebar ? t(menu.name):''}>
                        <ListItem
                          id={menu.name}
                          key={index}
                          button
                          selected={selectedIndex === index}
                          onClick={() => handleListItemClick(index)}
                          >
                          <ListItemIcon>
                            {
                              menu.icon
                            }
                          </ListItemIcon>
                          <ListItemText primary={t(`main-layout-component.${menu.name}`)}/>
                        </ListItem>
                      </Tooltip>
                      <MenuPartition name={menu.name} isHidden={sidebar} roles={administrator?.roles}/>
                    </Box>

                    )
                  })
                }
        </List>
      </Drawer>
      <main className={clsx(isCompactView ? classes.contentCompactView : classes.content)}>
        <div className={classes.appBarSpacer} />
        {
          children
        }
      </main>
    </div>
  );
};

export default MainLayout;