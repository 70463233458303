import DateFnsUtils from '@date-io/date-fns';
import { Backdrop, Box, Button, Container, Fade, Grid, Link, MenuItem, Modal, TextField } from '@material-ui/core';
import { MuiPickersUtilsProvider } from '@material-ui/pickers';
import clsx from 'clsx';
import deLocale from 'date-fns/locale/de';
import enLocale from 'date-fns/locale/en-US';
import { Form, Formik } from 'formik';
import cookies from 'js-cookie';
import React, { useCallback, useContext, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import DatePicker from '../../../components/date-picker';
import Select from '../../../components/select';
import { LocationContext } from '../../../context/locationContext';
import { CLEAR_FILTER } from '../../../reducer/eventsFilterReducer';
import { getEventControllers } from '../../../service/eventsApi';
import { ACCESS_CHECK, ACCESS_CHECK_VALUES, ALL, ASCENDING, DEFAULT_DATE, DEVICE, DEVICE_REQUEST, DOOR, DOOR_VALUES, EMA_VALUES, Filter_TYPES, IMPORT_EXPORT_VALUES, LANGUAGE_DE, LANGUAGE_EN, READER, READER_VALUES, SDDL_VALUES, SERVICE_VALUES, UPDATE_DAEMON } from '../../../utility/constants';
import useStyles from './styles';

const EventsFilter = (props) => {
  const { clearFields, setIsClearFilter, handleClose, initialValues, onSubmit, accessPointList, setValidFrom, setValidUntil, setIsNotValid, setSubTypes, setAccessPoints, setCredentialNumber, handleSelectChange, controllers, handleSelectSubTypeChange, handleSelectTypeChange } =  props
  
  const classes                                         = useStyles();
  const { t }                                           = useTranslation();
  const [subTypeItems, setSubTypeItems]                 = useState([]);
  const [typeItems, setTypeItems]                       = useState([]);
  const [showAccessPoint, setShowAccessPoint]           = useState(false);
  const [showCredentialNumber, setShowCredentialNumber] = useState(false);
  const [showSubtype, setShowSubtype]                   = useState(false);
  
  const { state } = useContext(LocationContext);

  useEffect(() => {
    const subItems = [...DOOR_VALUES, ...READER_VALUES, ...ACCESS_CHECK_VALUES, ...EMA_VALUES, ...SDDL_VALUES, ...SERVICE_VALUES, ...IMPORT_EXPORT_VALUES, ...UPDATE_DAEMON];

    const types = initialValues.type.map(type => type.name);

    const showSelectItems = (item) => {
      return types.includes(item);
    }
    
    initialValues.type.forEach(type => {
      if (type.name !== DEVICE_REQUEST) {
        setShowSubtype(true);
      }
    })

    const handleChangeSubTypeMenu = () => {
      let subTypeMenuItems = [];

      initialValues.type.map((value) => {
        if (value.name === ALL) {
          subTypeMenuItems = subItems
          return null;
        }

        (value.name === "Door")          && subTypeMenuItems.push(...DOOR_VALUES);
        (value.name === "Reader")        && subTypeMenuItems.push(...READER_VALUES);
        (value.name === "Access Check")  && subTypeMenuItems.push(...ACCESS_CHECK_VALUES);
        (value.name === "EMA")           && subTypeMenuItems.push(...EMA_VALUES);
        (value.name === "SDDL")          && subTypeMenuItems.push(...SDDL_VALUES);
        (value.name === "Service")       && subTypeMenuItems.push(...SERVICE_VALUES);
        (value.name === "Import Export") && subTypeMenuItems.push(...IMPORT_EXPORT_VALUES); 
        (value.name === "Update Daemon") && subTypeMenuItems.push(...UPDATE_DAEMON);
        return null;
      })

      const uniqueSubTypeMenuItems = [...new Set(subTypeMenuItems)];

      //Filter selected subtypes based on selected types
      if (initialValues.type.length) {
        setSubTypes(subTypes => {
          return subTypes.filter(subtype => {
            return uniqueSubTypeMenuItems.includes(subtype.name)
          });
        });
      }

      setSubTypeItems(uniqueSubTypeMenuItems);
    }
    
    setShowAccessPoint(showSelectItems(DOOR));
    setShowCredentialNumber(showSelectItems(READER) || showSelectItems(ACCESS_CHECK));

    handleChangeSubTypeMenu();

  }, [initialValues.type, setSubTypes]);
  
  useEffect(() => {
    setTypeItems(Filter_TYPES);
  }, []);

  const resetForm = () => {
    setIsClearFilter(true);
    clearFields();
  }

  const getAccessPointItems = () => {
    let filteredAccessPointsList = accessPointList;
    
    if (state.selectedLocationId) {
      filteredAccessPointsList = accessPointList.filter(accessPoints => accessPoints.locationId === state.selectedLocationId);
    }  

    if (controllers?.length > 0) {
      const filteredWithDeviceAccessPoints = controllers.map(controller => {
        return filteredAccessPointsList.filter(accessPoint => accessPoint.displayName.includes(controller.name));
      });
      
      filteredAccessPointsList = filteredWithDeviceAccessPoints.flatMap(accessPoints => accessPoints.map(accessPoint => accessPoint));
    }
    
    return filteredAccessPointsList.map((accesspoint, index) => {
      const { name, displayName } = accesspoint;
      
      return(
        <MenuItem value={name} key={index} onClick={() => setAccessPoints(prev => name === prev ? '' : name)}>
          <span id={`eventsAccessPoints${index}Name`}> {displayName}</span>
        </MenuItem>
      );
    });
  }

  const handleSet = (setter, formik) => {
    return(setter, formik);
  }

  return(
    <Formik
      enableReinitialize
      initialValues={initialValues}
      onSubmit={onSubmit}
      resetForm
    >
      {
        formik => (
          <Form>
            <Grid container spacing={2} className={`${classes.paper} ${classes.form}`}>
              <Grid item md={6} sm={6}>
                <SelectTypes id="eventsSelectTypes" handleSelectTypeChange={handleSelectTypeChange} typeItems={typeItems}  types={initialValues.type}/>
              </Grid>
              <Grid item md={6} sm={6}>
                <SelectControllers id="eventsSelectController" handleSelectChange={handleSelectChange} controllers={controllers}/>
              </Grid>
              <Grid item md={6} sm={6}>
                <DatePicker
                  id="eventsValidFrom"
                  name="validFrom"
                  disabled={false}
                  until={formik.values.validUntil}
                  label={t(`from`)}
                  value={formik.values.validFrom}
                  handleChange={setValidFrom}
                  touched={formik.touched.validFrom}
                  error={formik.errors.validFrom}
                  setIsNotValid={setIsNotValid}
                  NotRequired={true}
                />
              </Grid>
              <Grid item md={6} sm={6}>
                <DatePicker
                  id="eventsValidUntil"
                  name="validUntil"
                  disabled={false}
                  label={t(`until`)}
                  min={formik.values.validUntil}
                  from={formik.values.validFrom ? formik.values.validFrom : DEFAULT_DATE}
                  value={formik.values.validUntil}
                  handleChange={setValidUntil}
                  touched={formik.touched.validUntil}
                  error={formik.errors.validUntil}
                  setIsNotValid={setIsNotValid}
                  NotRequired={true}
                />
              </Grid>
              <Grid item md={6} sm={6} className={clsx((showSubtype ? '' : 'hidden'))}>
                <SelectSubTypes handleSelectSubTypeChange={handleSelectSubTypeChange}  subTypeItems={subTypeItems} subTypes={initialValues.subTypes}/>
              </Grid>
              <Grid item md={6} sm={6} className={clsx((showAccessPoint ? '' : 'hidden'))}>
                <TextField
                  className={classes.fullWidth}
                  id="eventsAccessPoints"
                  label={t(`accessPoint`)}
                  name="accessPoints"
                  value={formik.values.accessPoints}
                  variant="outlined"
                  error={formik.touched.accessPoints && Boolean(formik.errors.accessPoints)}
                  helperText={t(formik.touched.accessPoints) && t(formik.errors.accessPoints)}
                  SelectProps={{
                    MenuProps: {
                      anchorOrigin: {
                        vertical: "bottom",
                        horizontal: "left"
                      },
                      getContentAnchorEl: null
                    }
                  }}
                  select
                >
                  { getAccessPointItems() }
                </TextField>
              </Grid>
              <Grid item md={6} sm={6} className={clsx((showCredentialNumber ? '' : 'hidden'))}>
                <TextField
                  className={classes.fullWidth}
                  id="eventsCredentialNumber"
                  label={t(`credentialNumber`)}
                  name="credentialNumber"
                  onChange={e => handleSet(setCredentialNumber(e.target.value), formik.handleChange(e))}
                  value={formik.values.credentialNumber}
                  variant="outlined"
                  error={formik.touched.credentialNumber && Boolean(formik.errors.credentialNumber)}
                  helperText={t(formik.touched.credentialNumber) && t(formik.errors.credentialNumber)}
                  />
              </Grid>
            </Grid>
            <Grid container className={classes.action}>
              <Grid item sm={6} xs={6}>
                  <Link
                    id="eventsClearFilter"
                    className={classes.filter}
                    onClick={() => resetForm()}
                    variant="body2"
                  >
                    {t(`clearFilters`)}
                  </Link>
              </Grid>
              <Grid item sm={6} xs={6} className={classes.buttons}>
                <Button
                  id="eventsCancelButton"
                  className={classes.button}
                  color="primary"
                  onClick={handleClose}
                  variant="outlined"
                >
                  {t(`cancel`)}
                </Button>
                <Button
                  id="eventsSubmitButton"
                  className={classes.button}
                  color="primary"
                  type="submit"
                  variant="contained"
                >
                  {t('confirm')}
                </Button>
              </Grid>
            </Grid>
          </Form>
        )
      }
    </Formik>
  );
}

const SelectControllers = (props) => {
  const { handleSelectChange, controllers } = props;
  const [isLoadingSelect, setIsLoadingSelect]     = useState(true);
  const [error, setError]                         = useState(false);
  const [items, setItems]                         = useState([]);
  const [hasMore, setHasMore]                     = useState(false);
  const [searchQuery, setSearchQuery]             = useState('');
  const [currentPageNumber, setCurrentPageNumber] = useState(0);
  
  const { t } = useTranslation();
  
  const searchHandler = useCallback((query, pageNumber) => {
    setSearchQuery(query);
    setCurrentPageNumber(pageNumber);
  }, []);

  const getControllersList = useCallback(async () => {
    setItems([]);
    setError(false);
    setIsLoadingSelect(true);
    
    const params = {
      keyword: searchQuery,
      size   : 30,
      page   : currentPageNumber,
      sort   : `${DEVICE},${ASCENDING}`
    }
  
    try {
      const response = await getEventControllers(params);
      const { data } = response;
      const { totalElements, totalPages } = data.page;

      const controllers = data._embedded.devices.map((device, index) => {
        return {
          id  : index,
          name: device,
        }
      });
  
      setItems(controllers);
      setHasMore(totalElements > 0 && currentPageNumber < totalPages - 1);
      setIsLoadingSelect(false);
    } catch {
      setError(true);
    }
  }, [currentPageNumber, searchQuery]);

  useEffect(() => {
    getControllersList();
  }, [getControllersList]);

  return (
    <Select
      id                = "eventsFilterController"
      search            = {searchHandler}
      selectFrom        = {'Controllers'}
      label             = {t('controller')}
      onChange          = {handleSelectChange}
      defaultValue      = {controllers}
      items             = {items}
      error             = {error}
      hasMore           = {hasMore}
      isLoadingSelect   = {isLoadingSelect}
      currentPageNumber = {currentPageNumber}
      searchQuery       = {searchQuery}
    />
  )
}

const SelectSubTypes = (props) => {
  const { handleSelectSubTypeChange, subTypeItems, subTypes } = props;
  const [items, setItems]                         = useState([]);
  const [hasMore, setHasMore]                     = useState(false);
  const [searchQuery, setSearchQuery]             = useState('');
  const [currentPageNumber, setCurrentPageNumber] = useState(0);
  
  const { t } = useTranslation();

  useEffect(() => {
    setItems(subTypeItems.map((multipleSubType, index) => {
      return {
        id         : index,
        name       : multipleSubType,
      }
    }));
  
    return () => {
      setItems([]);
    }
  }, [subTypeItems]);
  
  const searchHandler = useCallback((query, pageNumber) => {
    setSearchQuery(query);
    setCurrentPageNumber(pageNumber);
  }, []);

  useEffect(() => {
    if(subTypeItems !== []) {
      let count = 0;

      if (searchQuery !== '') {
        setItems((subTypeItems.filter((value) => { 
          return value.toLowerCase().includes(searchQuery.toLowerCase()) ;
        }).map((multipleSubType) => {
          count = count + 1;
          return {
            id         : count,
            name       : multipleSubType,
          }
        })));
      } else {
        setItems(subTypeItems.map(multipleSubType => {
          count = count + 1;
          return {
            id         : count,
            name       : multipleSubType,
          }
        }));
      }

      setHasMore(false);
    }
  },[searchQuery, currentPageNumber, subTypeItems]);

  return (
    <Select
      id                = "eventsFilterSubTypes"
      search            = {searchHandler}
      selectFrom        = {'Sub Types'}
      label             = {t('subTypes')}
      onChange          = {handleSelectSubTypeChange}
      defaultValue      = {subTypes}
      items             = {items}
      hasMore           = {hasMore}
      currentPageNumber = {currentPageNumber}
      searchQuery       = {searchQuery}
    />
  )
}

const SelectTypes = (props) => {
  const { handleSelectTypeChange,  types, typeItems} = props;
  const [items, setItems]                         = useState([]);
  const [hasMore, setHasMore]                     = useState(false);
  const [searchQuery, setSearchQuery]             = useState('');
  const [currentPageNumber, setCurrentPageNumber] = useState(0);
  
  const { t } = useTranslation();

  useEffect(() => {
    setItems(typeItems.map((multipleType, index) => {
      return {
        id         : index,
        name       : multipleType,
      }
    }));
  
    return () => {
      setItems([]);
    }
  }, [typeItems]);

  const searchHandler = useCallback((query, pageNumber) => {
    setSearchQuery(query);
    setCurrentPageNumber(pageNumber);
  }, []);

  useEffect(() => {
    setItems([]);

    if(typeItems !== []) {
      let count = 0;

      if (searchQuery !== '') {
        setItems((typeItems.filter((value) => { 
          return value.toLowerCase().includes(searchQuery.toLowerCase()) ;
        }).map((typeItem) => {
          count = count + 1;
          return {
            id         : count,
            name       : typeItem,
          }
        })));
      } else {
        setItems(typeItems.map(typeItem => {
          count = count + 1;
          return {
            id         : count,
            name       : typeItem,
          }
        }));
      }

      setHasMore(false);
    }
  }, [searchQuery, currentPageNumber, typeItems]);

  return (
    <Select
      id                = "eventFilterTypes"
      search            = {searchHandler}
      selectFrom        = {'Types'}
      label             = {t('types')}
      onChange          = {handleSelectTypeChange}
      defaultValue      = {types}
      items             = {items}
      hasMore           = {hasMore}
      currentPageNumber = {currentPageNumber}
      searchQuery       = {searchQuery}
    />
  )
}

const ModalEventsFilter = (props) => {
  const classes = useStyles();
  const { open, clearFields, handleClose, filterValues, initFilterValues, accessPointList, setValidFrom, setValidUntil, setIsNotValid, setTypes, types, setSubTypes, subTypeMenuItems, setAccessPoints, setCredentialNumber, controllers, setControllers, subTypes, dispatch, isClearFilter, setIsClearFilter, setEventsFilterSubTypes } = props;

  const language = cookies.get('i18next') || LANGUAGE_EN;

  const handleSubmit = (values, formik) => {
    if (isClearFilter) {
      dispatch({ type: CLEAR_FILTER });
    }
    
    values.subTypes = subTypes;
    filterValues(values, formik);
    setIsClearFilter();
  }

  const handleSelectChange = (newItems) => {
    setControllers(newItems);
  }

  const handleSelectSubTypeChange = (newItems) => {
    setEventsFilterSubTypes(newItems);
    setSubTypes(newItems);
  }

  const handleSelectTypeChange = (newItems) => {
    if (!newItems.includes(DOOR)) {
      setAccessPoints('');
    }
    setTypes(newItems);
  }

  useEffect(() => {
    if (types.length === 0) {
      setSubTypes([]);
    }
  }, [types, setSubTypes]);

  return (
    <MuiPickersUtilsProvider utils={DateFnsUtils} locale={language === LANGUAGE_DE ? deLocale : enLocale}>
      <Modal
        disableEnforceFocus
        open={open}
        onClose={handleClose}
        closeAfterTransition
        BackdropComponent={Backdrop}
        BackdropProps={{
          timeout: 500,
        }}
        className={classes.filterModal}
        autoFocus={false}
      >
        <Fade in={open}>
          <Container className={`${classes.modalContent}`}>
            <Box className={`${classes.titleFilterModal} ${'bold'} ${classes.form}`}> Filter </Box>
            <hr className={classes.hrDividerProfile} />
              <EventsFilter
                accessPointList={accessPointList}
                controllers={controllers}
                handleClose={handleClose}
                handleSelectChange={handleSelectChange}
                initialValues={initFilterValues}
                onSubmit={handleSubmit}
                setAccessPoints={setAccessPoints}
                setCredentialNumber={setCredentialNumber}
                setControllers={setControllers}
                setIsNotValid={setIsNotValid}
                setSubTypes={setSubTypes}
                setTypes={setTypes}
                setValidFrom={setValidFrom}
                setValidUntil={setValidUntil}
                subTypeMenuItems={subTypeMenuItems}
                types={types}
                handleSelectSubTypeChange={handleSelectSubTypeChange}
                subTypes={subTypes}
                handleSelectTypeChange={handleSelectTypeChange}
                dispatch={dispatch}
                setEventsFilterSubTypes={setEventsFilterSubTypes}
                setIsClearFilter={setIsClearFilter}
                clearFields={clearFields}
              />
          </Container>
        </Fade>
      </Modal>
    </MuiPickersUtilsProvider>
  );
}

export default ModalEventsFilter;