import DateFnsUtils from '@date-io/date-fns';
import { Box, Button, Container, FormControlLabel, Grid, IconButton, Paper, Switch, TextField, Tooltip, Typography } from '@material-ui/core';
import { Edit } from '@material-ui/icons';
import { MuiPickersUtilsProvider } from '@material-ui/pickers';
import clsx from 'clsx';
import deLocale from "date-fns/locale/de";
import enLocale from 'date-fns/locale/en-US';
import { Form, Formik } from 'formik';
import cookies from 'js-cookie';
import moment from "moment";
import React, { useCallback, useContext, useEffect, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Prompt, useHistory } from 'react-router';
import DatePicker from '../../components/date-picker';
import SelectItems from '../../components/select-items';
import Title from '../../components/title';
import UnsavedModal from '../../components/unsaved-modal';
import { AuthContext } from '../../context/authContext';
import { LocationContext } from '../../context/locationContext';
import api from '../../service/api';
import { multipleRequest, request } from '../../service/requests';
import { getUserApi } from '../../service/usersApi';
import { ACTION_UPDATE, ACTION_VIEW, API_REQUEST_ERROR_MESSAGE, CREDENTIALS_MODULE, DATE_FORMAT, DATE_FORMAT_YYYY_MM_DD, GET, LANGUAGE_DE, LANGUAGE_EN, LOCATIONS_MODULE, MAX_CHARACTER_LIMIT, PATCH, POST, PROJECTION, PUT, USERS_MODULE } from '../../utility/constants';
import { GetInitialLocationId, GetInitialLocationObject, assignLocation, getLocation, hasLocationChange, unassignLocation } from '../../utility/location';
import { convertToUTC } from '../../utility/helper';
import { credentialSchema } from '../../validation/schema';
import CredentialSkeleton from './credential-skeleton';
import useStyles from './styles';

const Content = (props) => {
  const classes = useStyles();
  const { t } = useTranslation();
  const { initialValues, handleSubmit, id, disabled, setValidFrom, setValidUntil, showToaster, selectedPerson, setCredentialNumber, setActive, setDescription, setCompanyCode, setIsNotValid, showLoading, handleCancel, handleSelectedPerson, handleSelectedLocation, selectedLocation, path, handlePermissions } = props;

  const isViewPage = path.includes(ACTION_VIEW);

  const getButtonLabel = () => {
    return id ? `${t('update')}` : `${t('create')}`;
  }

  const handleChange = (setter, formik) => {
    return(setter, formik);
  }

  return (
    <Formik
      enableReinitialize
      initialValues={initialValues}
      validationSchema={credentialSchema}
      onSubmit={handleSubmit}
    >
      {
        formik => (
          <Form>
            {showLoading(formik.isSubmitting)}
            <Paper className={classes.paper} elevation={3}>
              <Grid container spacing={2} className={classes.form}>
                <Grid item xs={12} sm={10} md={9} lg={8} className={classes.grid}>
                  <TextField
                    inputProps={{
                      readOnly: disabled,
                      maxlength: MAX_CHARACTER_LIMIT.TEXT_FIELD
                    }}
                    id="credentialNumber"
                    label={`${t('credentialNumber')}*`}
                    name="credentialNumber"
                    disabled = {path.includes(ACTION_UPDATE) || path.includes(ACTION_VIEW)}
                    fullWidth
                    multiline
                    value={formik.values.credentialNumber}
                    onChange={e => handleChange(setCredentialNumber(e.target.value), formik.handleChange(e))}
                    error={formik.touched.credentialNumber && Boolean(formik.errors.credentialNumber)}
                    helperText={t(formik.touched.credentialNumber) && t(formik.errors.credentialNumber)}
                  />
                </Grid>
                <Box component={Grid} item lg display={{ xs: 'none', sm: 'none', md: 'none' }}></Box>
                <Grid item xs={12} sm md lg>
                  <Box className={classes.switchContainer}>
                    <FormControlLabel
                      id="activeSwitch"
                      disabled={path.includes(ACTION_VIEW)}
                      labelPlacement="start"
                      control={<Switch disabled={disabled} color="primary"/>}
                      label={formik.values.active ? `${t('active')}` : `${t('inactive')}`}
                      focusVisibleClassName=".Mui-focusVisible"
                      checked={formik.values.active}
                      onChange={e => handleChange(setActive(!formik.values.active), formik.handleChange(e))}
                    />
                  </Box>
                </Grid>
                <Grid item xs={12} className={classes.grid}>
                  <TextField
                    inputProps={{
                      readOnly: disabled,
                      maxlength: MAX_CHARACTER_LIMIT.TEXT_FIELD
                    }}
                    disabled={path.includes(ACTION_VIEW)}
                    id="description"
                    placeholder={t('noDescription')}
                    label={t('description')}
                    name="description"
                    fullWidth
                    value={formik.values.description}
                    onChange={e => handleChange(setDescription(e.target.value), formik.handleChange(e))}
                    multiline
                    rows={6}
                  />
                  {
                    !isViewPage &&
                      <span
                        className={formik.errors.description && formik.touched.description ? classes.characterLimitError : classes.characterLimit}>
                        { 
                          t('controller-page.characterLimit', { 
                            currentCharacterCount: formik.values.description?.length, maxCharacterCount: MAX_CHARACTER_LIMIT.TEXT_FIELD 
                          })
                        }
                      </span>
                  }
                </Grid>
                <Grid item xs={12} md={6} lg={3}>
                  <DatePicker
                    name="validFrom"
                    disabled={disabled}
                    label={t('validFrom')}
                    until={formik.values.validUntil}
                    value={formik.values.validFrom}
                    handleChange={setValidFrom}
                    touched={formik.touched.validFrom}
                    error={formik.errors.validFrom}
                    setIsNotValid={setIsNotValid}
                  />
                </Grid>
                <Grid item xs={12} md={6} lg={3}>
                  <DatePicker
                    name="validUntil"
                    disabled={disabled}
                    label={t('validUntil')}
                    from={formik.values.validFrom}
                    min={formik.values.validUntil}
                    value={formik.values.validUntil}
                    handleChange={setValidUntil}
                    touched={formik.touched.validUntil}
                    error={formik.errors.validUntil}
                    setIsNotValid={setIsNotValid}
                  />
                </Grid>
                <Grid item xs={12} md={12} lg={6} className={classes.grid}>
                  <TextField
                    disabled={path.includes(ACTION_VIEW)}
                    inputProps={{
                      readOnly: disabled,
                      maxlength: MAX_CHARACTER_LIMIT.TEXT_FIELD
                    }}
                    id="companyCode"
                    label={t('companyCode')}
                    name="companyCode"
                    fullWidth
                    value={formik.values.companyCode}
                    onChange={e => handleChange(setCompanyCode(e.target.value), formik.handleChange(e))}
                  />
                </Grid>
                <Grid item xs={12} md={12} lg={6} className={clsx(!handlePermissions(USERS_MODULE, PUT) && 'hidden')}>
                  <SelectItems
                    disabled={disabled}
                    name='Users'
                    onChange={handleSelectedPerson}
                    selectedItems={selectedPerson}
                    showToaster={showToaster}
                    single={true}
                    handlePermissions={handlePermissions}
                  />
                </Grid>
                <Grid item xs={12} md={12} lg={6} className={clsx(!handlePermissions(LOCATIONS_MODULE, GET) && 'hidden')}>
                  <SelectItems
                    helperText={t(formik.touched.location) && t(formik.errors.location)}
                    isValid={formik.touched.location && Boolean(formik.errors.location)}
                    name="Locations"
                    onChange={handleSelectedLocation}
                    disabled={disabled}
                    selectedItems={selectedLocation}
                    showToaster={showToaster}
                    single={true}
                    required={true}
                    handlePermissions={handlePermissions}
                  />
                </Grid>
              </Grid>
              <Grid container className={clsx(disabled ? 'hidden' : classes.action)}>
                <Grid item xs={12}>
                  <Button
                    onClick={handleCancel}
                    variant="outlined"
                    color="primary"
                    >
                    {t('cancel')}
                  </Button>
                  <Button
                    type="submit"
                    variant="contained"
                    color="primary"
                  >
                    {getButtonLabel()}
                  </Button>
                </Grid>
              </Grid>
            </Paper>
          </Form>
        )
      }
    </Formik>
  )
}

const Credential = (props) => {
  const { t }                               = useTranslation();
  const { showToaster, match, showLoading, handlePermissions } = props;
  const path                                = match.path;
  const { id }                              = match.params;
  const classes                             = useStyles();
  const history                             = useHistory();

  const { state : tenantState }                  = useContext(AuthContext);
  const { defaultValidFrom, defaultValidUntil }  = tenantState;
  const administrator                            = tenantState.administrator;
  
  const { state }  = useContext(LocationContext);

  const initialLocationId = GetInitialLocationId();
  const initialLocationObject = GetInitialLocationObject();

  const [active, setActive]                           = useState(true);
  const [companyCode, setCompanyCode]                 = useState('');
  const [credentialNumber, setCredentialNumber]       = useState('');
  const [description, setDescription]                 = useState('');
  const [isLoading, setIsLoading]                     = useState(false);
  const [isNotValid, setIsNotValid]                   = useState(false);
  const [user, setUser]                               = useState([]);
  const [userId, setUserId]                           = useState(null);
  const [prevValues, setPrevValues]                   = useState([]);
  const [showModal, setShowModal]                     = useState(false);
  const [validFrom, setValidFrom]                     = useState(defaultValidFrom);
  const [validUntil, setValidUntil]                   = useState(defaultValidUntil);
  const [withChanges, setWithChanges]                 = useState(false);
  const [toRedirect, setToRedirect]                   = useState('');
  const [location, setLocation]                       = useState('');
  const [selectedLocation, setSelectedLocation]       = useState(initialLocationId);
  const [locationObject, setLocationObject]           = useState(initialLocationObject);

  const language = cookies.get('i18next') || LANGUAGE_EN;

  const initialValues = useMemo(() => {
    return {
      credentialNumber  : credentialNumber,
      description       : description,
      validFrom         : validFrom,
      validUntil        : validUntil,
      companyCode       : companyCode,
      active            : active,
      userId            : userId,
      location          : selectedLocation || selectedLocation === undefined ? selectedLocation : location
    }
  }, [credentialNumber, description, validFrom, validUntil, companyCode, active, userId, selectedLocation, location]);

  const handleCloseModal = () => {
    setShowModal(false);
  }

  const handleCancel = () => {
    history.push('/credentials');
  }

  const handleModalSubmit = () => {
    setWithChanges(false)
    history.push(toRedirect);
  }

  const handleModalCancel = () => {
    handleChanges();
    setShowModal(false);
  }

  const getUser = async (userId) => {
    let user = '';
    if (userId) {
      user = await getUserApi(userId);
    }
    if (user) {
      const results = {
        userId      : user.userId,
        name        : `${user.lastName}, ${user.firstName}`,
        description : `${moment(user.validFrom).format(DATE_FORMAT)} - ${moment(user.validUntil).format(DATE_FORMAT)}`,
      }

      setUser([...new Set([results])]);
    }
  }

  const getCredential = useCallback(async () => {
    setIsLoading(true);

    try {
      const response = await request({
        url     : api.CREDENTIALS_BY_ID,
        params  : {
            credentialId : id,
            projection   : PROJECTION.CREDENTIALS
        },
        method  : GET,
      });
      const userId = response.data.userId;

      await getUser(userId);
      getLocation(response.data, setLocation, setLocationObject);
      setCredentialNumber(response.data.credentialNumber);
      setDescription(response.data.description);
      setValidUntil(convertToUTC(response.data.validUntil).format(DATE_FORMAT_YYYY_MM_DD));
      setValidFrom(convertToUTC(response.data.validFrom).format(DATE_FORMAT_YYYY_MM_DD));
      setCompanyCode(response.data.companyCode);
      setActive(response.data.active === 1 ? true : false);
      setUserId(userId ? userId : null);

      setPrevValues({
        active          : response.data.active === 1 ? true : false,
        companyCode     : response.data.companyCode,
        credentialNumber: response.data.credentialNumber,
        description     : response.data.description,
        userId          : userId ? userId : null,
        validFrom       : convertToUTC(response.data.validUntil).format(DATE_FORMAT_YYYY_MM_DD),
        validUntil      : convertToUTC(response.data.validFrom).format(DATE_FORMAT_YYYY_MM_DD),
      });
    } catch (error) {
      showToaster(t('error'), t(API_REQUEST_ERROR_MESSAGE), 'error');
    } finally {
      setIsLoading(false);
    }
  }, [id, showToaster, t]);

  const getToasterMessage = () => {
    return id ? t(`updated`) : t(`created`);
  }

  const handleSubmit = async (values, formik) => {
    const { setSubmitting } = formik;

    if (isNotValid) {
      setSubmitting(false);
      return;
    }

    if (!withChanges) {
      setSubmitting(false);
      setWithChanges(false);
      history.push('/credentials');
      return;
    }

    if (id || !values.credentialNumber.includes(',')) {
      await handleMultipleCredentials(values, formik);
    } else {
      await checkExisting(values, formik);
    }
  }

  const handleMultipleCredentials = async (values, formik) => {
    const { setErrors, setSubmitting } = formik;

    let separatedCredentials = (values.credentialNumber.split(",")).filter((value => { 
      return value.trim() !== '';
    })).map((value) => value.trim());

    separatedCredentials = [...new Set(separatedCredentials)];

    try {
      const responses = await multipleRequest(
        separatedCredentials.map(separatedCredential =>
          request({
            url     : id ? `${api.CREDENTIALS}/${id}` : api.CREDENTIALS,
            method  : id ? PATCH : POST,
            data    : {
              credentialNumber  : separatedCredential.trim(),
              description       : values.description,
              companyCode       : values.companyCode,
              validFrom         : values.validFrom,
              validUntil        : values.validUntil,
              active            : values.active ? 1 : 0,
              userId            : userId
            },
          })
        )
      );

      const credentialUrls = responses.map((response) => {
        return response.data._links.self.href;
      });

      for (const credentialUrl of credentialUrls) {
        await assignLocationToCredential(credentialUrl);
      }

      showToaster(t('success'), `${values.credentialNumber} ${t('hasBeen')} ${getToasterMessage()}`, ('success'));
      setWithChanges(false);
      setSubmitting(false);
      history.push('/credentials');
    } catch (error) {
      if (error?.response?.status === 409) {
        setErrors({
          credentialNumber: t('credentialNumberAlreadyExists')
        });
      } else {
        showToaster(t('error'), t(API_REQUEST_ERROR_MESSAGE), 'error');
      }
    }
  }

  const handleSelectedLocation = (value) => {
    setSelectedLocation(value[0]?.locationId);
    setLocationObject(value);
  }

  const handleSelectedPerson = (value) => {
    if (value.length) {
      setUser(value);
      value[0].id ? setUserId(value[0].id) : setUserId(value[0].userId);
    } else {
      setUserId(null);
      setUser([]);
    }
  }

  const checkExisting = async (values, formik) => {
    const responses = await request({
      url     : `${api.CREDENTIALS_FIND_BY_CREDENTIAL_NUMBER_IN}`,
      method  : GET,
      params  : {
        credentialNumbers : values.credentialNumber
      }
    })

    if (responses.data._embedded.credentials.length > 0) {
      formik.setErrors({
        credentialNumber: t('credentialNumberAlreadyExistsMultiple')
      });
      formik.setSubmitting(false);
    } else {
      await handleMultipleCredentials(values, formik);
    }
  }

  const assignLocationToCredential = async (link) => {
    if (location === locationObject[0]?.locationId) {
      return;
    }

    if (location && selectedLocation) {
      await unassignLocation(link);
    } 

    await assignLocation(link, selectedLocation);
  }

  const initialValidUntil = (value) => {
    if(value){
      if(value.toString().includes('.')){
        return value;
      } else {
        return (value) ? moment(value).format(DATE_FORMAT_YYYY_MM_DD) : '';
      }
    } else {
      return value
    }
  }

  const checkCredentialChanges = useCallback(() => {
    const hasNoChange = prevValues.credentialNumber === initialValues.credentialNumber
      && prevValues.description === initialValues.description
      && initialValidUntil(prevValues.validFrom)  === moment(initialValues.validFrom).format(DATE_FORMAT_YYYY_MM_DD)
      && initialValidUntil(prevValues.validUntil) === initialValidUntil(initialValues.validUntil)
      && prevValues.companyCode === initialValues.companyCode
      && prevValues.active === initialValues.active

     return hasNoChange;
  }, [initialValues, prevValues]);

  const handleChanges = useCallback(() => {
    if (checkCredentialChanges() 
      && !hasLocationChange(location, locationObject[0]?.locationId, administrator.locations.length, state) 
      && prevValues.userId === initialValues.userId) {
      setWithChanges(false);
    } else {
      setWithChanges(true);
    }
  }, [location, checkCredentialChanges, initialValues, prevValues, administrator, locationObject, state]);

  useEffect(() => {
    setPrevValues({
      credentialNumber  : '',
      description       : '',
      validFrom         : defaultValidFrom,
      validUntil        : defaultValidUntil,
      companyCode       : '',
      active            : true,
      userId          : null
    });

    if (id) {
      getCredential();
    }
  }, [getCredential, id, defaultValidFrom, defaultValidUntil]);

  useEffect(() => {
    handleChanges();
  }, [handleChanges]);  

  return (
    <MuiPickersUtilsProvider utils={DateFnsUtils} locale={language === LANGUAGE_DE ? deLocale : enLocale}>
      <Container maxWidth="xl" className={classes.container}>
        <Prompt
          when={withChanges}
          message={(location, action) => {
            if (action === 'PUSH') {
              setShowModal(true)
            }
            setWithChanges(false);
            setToRedirect(location.pathname);
            return location.pathname === '/' || location.pathname === '/credentials/update' || location.pathname === '/credentials/create'
          }}
        />
        <UnsavedModal
          open={showModal}
          onClose={handleCloseModal}
          handleModalSubmit={handleModalSubmit}
          handleModalCancel={handleModalCancel}
        />
        <Title title={t('credential')} subtitle={`${credentialNumber}`}/>
          <Box>
            <Grid container>
              <Grid item xs={6}>
                  <Typography className={'bold'} color="secondary">Details</Typography>
              </Grid>
              <Grid item xs={6} align="right" className={clsx(path.includes(ACTION_VIEW) ? '' : 'hidden')}>
              <Tooltip title={t('update')} className={path.includes(ACTION_VIEW) && handlePermissions(CREDENTIALS_MODULE, PATCH) ? '' : 'hidden'}>
                <IconButton id="updateButton" aria-label="Update" onClick={() => history.push(`../update/${id}`)}>
                  <Edit className={classes.editIcon} />
                </IconButton>
              </Tooltip>
            </Grid>
            </Grid>
            {
              isLoading ?
                <CredentialSkeleton id={id} disabled={path.includes(ACTION_VIEW)} handlePermissions={handlePermissions}/>
              :
                <Content
                  disabled={path.includes(ACTION_VIEW)}
                  handleCancel={handleCancel}
                  handleSelectedPerson={handleSelectedPerson}
                  handleSelectedLocation={handleSelectedLocation}
                  handleSubmit={handleSubmit}
                  id={id}
                  initialValues={initialValues}
                  selectedPerson={user}
                  selectedLocation={locationObject}
                  setActive={setActive}
                  setCompanyCode={setCompanyCode}
                  setCredentialNumber={setCredentialNumber}
                  setDescription={setDescription}
                  setIsNotValid={setIsNotValid}
                  setValidFrom={setValidFrom}
                  setValidUntil={setValidUntil}
                  showLoading={showLoading}
                  showToaster={showToaster}
                  path={path}
                  handlePermissions={handlePermissions}
                />
            }
          </Box>
      </Container>
    </MuiPickersUtilsProvider>
  );
}

export default Credential;